import React, { useEffect } from "react";
import { reaction } from "mobx";
import { useLocalObservable, observer } from "mobx-react-lite";
import { Grid } from "@mui/material";
import FieldContainer from "./FieldContainer";

// Types
import { createFormStore, Field } from "./formHelpers";

type Props = {
  fieldKey: string;
  fields: Field[];
  forceShowErrors: boolean;
  disabled: boolean;
  onChange: (fieldKey: string, data: any) => void;
  setValidity: (key: string, valid: boolean) => void;
};

const FieldGroup: React.FC<Props> = observer(
  ({ fieldKey, fields, forceShowErrors, disabled, onChange, setValidity }) => {
    const locals = useLocalObservable(() => createFormStore({ tree: fields }));

    const sendValidity = () => {
      setValidity(fieldKey, locals.valid);
    };

    const sendValues = () => {
      const data = Object.values(locals.editableFieldsByKey).reduce(
        (result: any, field: any) => {
          result[field.key] = field.value.data;
          return result;
        },
        {},
      );
      onChange(fieldKey, data);
    };

    // weird syntax to do watch like in vue
    useEffect(() => {
      reaction(() => locals.valid, sendValidity);
      sendValidity();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <Grid container spacing={3}>
        <FieldContainer
          field={{ fields: locals.fieldsTree }}
          forceShowErrors={forceShowErrors}
          disabled={disabled}
          onChange={(fieldKey: string, data: any) => {
            locals.updateField(fieldKey, data);
            sendValues();
          }}
          setValidity={locals.updateValidity}
        />
      </Grid>
    );
  },
);

export default FieldGroup;

import { createTheme, alpha } from "@mui/material/styles";
import { grey } from "@mui/material/colors";

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    grey: true;
  }
}

declare module "@mui/material" {
  interface Color {
    main: string;
    dark: string;
  }
}

const defaultTheme = createTheme({});
const { breakpoints } = defaultTheme;

export const theme = createTheme({
  typography: {
    fontFamily: "SF Pro Display, sans-serif",
    h1: {
      fontSize: "56px",
      lineHeight: "1.07143",
      fontWeight: 600,
      color: "#333333",
      [breakpoints.down("sm")]: {
        fontSize: "44px",
        lineHeight: "1.09091",
      },
      [breakpoints.down("xs")]: {
        fontSize: "32px",
        lineHeight: "1.125",
      },
    },
    h2: {
      fontSize: "40px",
      lineHeight: "1.1",
      fontWeight: 600,
      color: "#333333",
      [breakpoints.down("sm")]: {
        fontSize: "32px",
        lineHeight: "1.125",
      },
      [breakpoints.down("xs")]: {
        fontSize: "28px",
        lineHeight: "1.14286",
      },
    },
    h3: {
      fontSize: "22px",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      color: "#333333",
    },
    h5: {
      fontSize: "22px",
      fontWeight: 500,
      fontStyle: "normal",
      fontFamily: "SF Pro Display",
    },
    h6: {
      textAlign: "left",
      fontSize: "21px",
      [breakpoints.down("xs")]: {
        fontSize: "16px",
        textAlign: "center",
      },
    },
    subtitle1: {
      fontSize: "28px",
      lineHeight: "1.10722",
      fontWeight: 400,
      [breakpoints.down("sm")]: {
        fontSize: "24px",
        lineHeight: "1.16667",
      },
      [breakpoints.down("xs")]: {
        fontSize: "19px",
        lineHeight: "1.4211",
      },
    },
    body2: {},
    button: {
      textTransform: "capitalize",
    },
  },
  palette: {
    primary: {
      main: "#027aff",
    },
    grey: {
      main: grey[300],
      dark: grey[400],
    },
    action: {
      disabledBackground: "#90c3ed",
      disabled: "white",
    },
  },

  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        disableFocusRipple: true,
        variant: "contained",
        color: "primary",
      },
      styleOverrides: {
        root: {
          cursor: "pointer",
          display: "inline-block",
          textAlign: "center",
          whiteSpace: "nowrap",
          fontSize: "17px",
          lineHeight: 1.17648,
          fontWeight: 400,
          letterSpacing: "-0.022em",
          fontFamily: "SF Pro Display",
          minWidth: "28px",
          paddingLeft: "16px",
          paddingRight: "16px",
          paddingTop: "8px",
          paddingBottom: "8px",
          borderRadius: "18px",
          margin: "0 5px",
        },
      },
      variants: [
        {
          props: { variant: "contained", color: "grey" },
          style: {
            color: defaultTheme.palette.getContrastText(
              defaultTheme.palette.grey[300],
            ),
          },
        },
        {
          props: { variant: "outlined", color: "grey" },
          style: {
            color: defaultTheme.palette.text.primary,
            borderColor:
              defaultTheme.palette.mode === "light"
                ? "rgba(0, 0, 0, 0.23)"
                : "rgba(255, 255, 255, 0.23)",
            "&.Mui-disabled": {
              border: `1px solid ${defaultTheme.palette.action.disabledBackground}`,
            },
            "&:hover": {
              borderColor:
                defaultTheme.palette.mode === "light"
                  ? "rgba(0, 0, 0, 0.23)"
                  : "rgba(255, 255, 255, 0.23)",
              backgroundColor: alpha(
                defaultTheme.palette.text.primary,
                defaultTheme.palette.action.hoverOpacity,
              ),
            },
          },
        },
        {
          props: { color: "grey", variant: "text" },
          style: {
            color: defaultTheme.palette.text.primary,
            "&:hover": {
              backgroundColor: alpha(
                defaultTheme.palette.text.primary,
                defaultTheme.palette.action.hoverOpacity,
              ),
            },
          },
        },
      ],
    },
    MuiButtonGroup: {
      defaultProps: {
        disableFocusRipple: true,
        variant: "contained",
        color: "primary",
      },
    },
    MuiLink: {
      defaultProps: {
        underline: "none",
      },
    },
  },
});

export default theme;

import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import { Grid, Typography, Divider, RadioGroup } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import {
  BasicLabel,
  BasicHelper,
  BasicRadio,
} from "./forms/inputs/CustomInputs";
import { Link } from "found";
import BackButton from "./BackButton";

const PREFIX = "CpaDialog";

const classes = {
  divider: `${PREFIX}-divider`,
  label: `${PREFIX}-label`,
  error: `${PREFIX}-error`,
  dialogContent: `${PREFIX}-dialogContent`,
  applyButton: `${PREFIX}-applyButton`,
  applyButtonDisabled: `${PREFIX}-applyButtonDisabled`,
  buttonContainer: `${PREFIX}-buttonContainer`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.divider}`]: {
    margin: "40px 0",
  },

  [`& .${classes.label}`]: {
    marginBottom: "10px",
  },

  [`& .${classes.error}`]: {
    color: "red",
  },

  [`& .${classes.dialogContent}`]: {
    marginTop: "16px",
  },

  [`& .${classes.applyButton}`]: {
    marginRight: "13px",
    width: "81px",
    height: "35px",
    borderRadius: "4px",
    border: "solid 1px #0177cc",
    backgroundImage: "linear-gradient(to bottom, #409feb, #0270ca 99%)",
    fontSize: "17px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "0.7px",
    "&$buttonDisabled": {
      color: "red",
    },
  },

  [`& .${classes.applyButtonDisabled}`]: {
    marginRight: "13px",
    width: "81px",
    height: "35px",
    borderRadius: "4px",
    border: "solid 1px #0177cc",
    backgroundImage: "linear-gradient(to bottom, #9fcef5, #7ab6e4 99%)",
    fontSize: "17px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "0.7px",
    color: "white",
  },

  [`& .${classes.buttonContainer}`]: {
    padding: "0 25px",
    marginBottom: "41px",
  },
}));

interface Props {
  linkTo: string;
}

const CpaDialog = ({ linkTo }: Props) => {
  const [open, setOpen] = React.useState(false);
  const [radioValue, setRadioValue] = React.useState<string>("");
  const [showErrors, setShowErrors] = React.useState<boolean>(false);

  useEffect(() => {
    radioValue === "false" ? setShowErrors(true) : setShowErrors(false);
  }, [radioValue]);

  const radioChange = (value: string) => {
    setRadioValue(value);
  };

  const handleClickOpen = () => {
    setOpen(true);
    setRadioValue("");
    setShowErrors(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Root>
      <Button onClick={handleClickOpen} disabled={!linkTo}>
        Next
      </Button>

      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        open={open}
        onClose={handleClose}
        aria-labelledby="cpa-dialog"
      >
        <DialogContent className={classes.dialogContent}>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item sm={3}>
                <Typography variant="h5">CPA Basis</Typography>
              </Grid>
              <Grid item sm={9}>
                <Grid container style={{ padding: "10px 10px" }}>
                  <Grid item xs={12}>
                    <FormControl disabled={false} fullWidth>
                      <BasicLabel>
                        Are you willing to work on a CPA-only basis?*
                      </BasicLabel>

                      <RadioGroup
                        row
                        value={radioValue}
                        onChange={(e) => {
                          radioChange(e.target.value);
                        }}
                      >
                        <Grid item xs={6}>
                          <FormControlLabel
                            value="true"
                            control={<BasicRadio />}
                            label="Yes"
                            labelPlacement="end"
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <FormControlLabel
                            value="false"
                            control={<BasicRadio />}
                            label="No"
                            labelPlacement="end"
                          />
                        </Grid>
                      </RadioGroup>

                      <BasicHelper>
                        <span
                          className={showErrors ? classes.error : classes.label}
                        >
                          * The Apple Services Performance Partners program only
                          works on a CPA basis and does not do flat-fee spend or
                          CPC payments
                        </span>
                      </BasicHelper>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Divider className={classes.divider} />
          </Grid>
        </DialogContent>

        <DialogActions>
          <Grid container justifyContent="flex-start" alignItems="center">
            <Grid item md={3} xs={12}></Grid>
            <Grid item md={9} xs={12} className={classes.buttonContainer}>
              <Link
                to={`/other-partners/${linkTo}`}
                as={Button}
                disabled={!radioValue || showErrors ? true : false}
              >
                Next
              </Link>

              {showErrors && <BackButton text="Quit" />}
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </Root>
  );
};

export default CpaDialog;

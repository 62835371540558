import React, { useState, useEffect } from "react";
import { checkTextValidity, InputFieldProps } from "../formHelpers";
import { reaction } from "mobx";
import { observer } from "mobx-react-lite";

import { FormControl, FormHelperText } from "@mui/material";
import { BasicInput, BasicLabel } from "./CustomInputs";
import ReactMarkdown from "react-markdown";

const TextInput: React.FC<InputFieldProps> = observer(
  ({ field, forceShowErrors, disabled, onChange, setValidity }) => {
    const [errorMessage, setErrorMessage] = useState("");
    const [showErrors, setShowErrors] = useState(false);

    const checkValidation = () => {
      // check text validations
      const errors = checkTextValidity(field);
      // set error messages
      setErrorMessage(errors.join(" "));
      setValidity(field.key, errors.length === 0);
    };

    // weird syntax to do watch like in vue
    useEffect(() => {
      reaction(() => field.value.data, checkValidation);
      if (!field.data?.validation?.required) checkValidation();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // check validation on init and when forceShowErrors updates
    useEffect(() => {
      checkValidation();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [forceShowErrors]);

    useEffect(() => {
      setShowErrors((forceShowErrors || field.changed) && errorMessage !== "");
    }, [forceShowErrors, errorMessage, field.changed]);

    return (
      <>
        <FormControl fullWidth error={showErrors} disabled={disabled}>
          <BasicLabel htmlFor={`${field.displayKey}_id`}>
            {field.name}
          </BasicLabel>

          <BasicInput
            id={`${field.displayKey}_id`}
            onChange={(e) => onChange(field.key, e.target.value)}
            onBlur={() => {
              field.changed = true;
            }}
            value={field.value.data || ""}
            placeholder={field.data?.settings?.placeholderText}
          />

          <FormHelperText component="span">
            <ReactMarkdown linkTarget={"_blank"}>
              {showErrors ? errorMessage : field.data?.settings?.helpText}
            </ReactMarkdown>
          </FormHelperText>
        </FormControl>
      </>
    );
  },
);

export default TextInput;

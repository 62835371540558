import React, { useState, useEffect } from "react";
import { reaction } from "mobx";
import { observer } from "mobx-react-lite";
import { checkOptionsValidity, InputFieldProps } from "../formHelpers";
import { FormControl, FormHelperText } from "@mui/material";

import countriesByRegion from "./data/countries";
import { BasicSelect, BasicLabel } from "./CustomInputs";
import ReactMarkdown from "react-markdown";

export type Option = {
  label: string;
  value: string;
};

const CountryInput: React.FC<InputFieldProps> = observer(
  ({ field, forceShowErrors, disabled, onChange, setValidity }) => {
    const [errorMessage, setErrorMessage] = useState("");
    const [showErrors, setShowErrors] = useState(false);

    const checkValidation = () => {
      // check text validations
      const errors = checkOptionsValidity(field);
      // set error messages
      setErrorMessage(errors.join(" "));
      setValidity(field.key, errors.length === 0);
    };

    // weird syntax to do watch like in vue
    useEffect(() => {
      reaction(() => field.value.data, checkValidation);
      if (!field.data?.validation?.required) checkValidation();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // check validation on init and when forceShowErrors updates
    useEffect(() => {
      checkValidation();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [forceShowErrors]);

    useEffect(() => {
      setShowErrors((forceShowErrors || field.changed) && errorMessage !== "");
    }, [forceShowErrors, errorMessage, field.changed]);

    const selectOptions = Object.keys(countriesByRegion).map(
      (region: string) => {
        return (
          <optgroup key={region} label={region}>
            {countriesByRegion[region].map((country) => {
              return (
                <option key={country.alpha2} value={country.alpha2}>
                  {country.name}
                </option>
              );
            })}
          </optgroup>
        );
      },
    );

    // render component
    return (
      <>
        <FormControl
          disabled={disabled}
          variant="outlined"
          fullWidth
          error={showErrors}
        >
          <BasicLabel htmlFor={`${field.displayKey}_id`}>
            {field.name}
          </BasicLabel>
          <BasicSelect
            id={`${field.displayKey}_id`}
            native
            value={field.value.data}
            onChange={(e: any) => {
              field.changed = true;
              onChange(field.key, e.target.value);
            }}
          >
            <option value="">Select Country</option>
            {selectOptions}
          </BasicSelect>
          <FormHelperText component="span">
            <ReactMarkdown linkTarget={"_blank"}>
              {showErrors ? errorMessage : field.data?.settings?.helpText}
            </ReactMarkdown>
          </FormHelperText>
        </FormControl>
      </>
    );
  },
);

export default CountryInput;

import React from "react";
import { styled } from "@mui/material/styles";
import {
  AppBar,
  Toolbar,
  Typography,
  Grid,
  Link as LinkOut,
} from "@mui/material";
import Link from "found/Link";

const PREFIX = "MenuBar";

const classes = {
  root: `${PREFIX}-root`,
  menuButton: `${PREFIX}-menuButton`,
  title: `${PREFIX}-title`,
  titleText: `${PREFIX}-titleText`,
  navBar: `${PREFIX}-navBar`,
  apple: `${PREFIX}-apple`,
  titleTwo: `${PREFIX}-titleTwo`,
  centerMenu: `${PREFIX}-centerMenu`,
  toolBar: `${PREFIX}-toolBar`,
  noHover: `${PREFIX}-noHover`,
};

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  [`& .${classes.root}`]: {
    flexGrow: 1,
  },

  [`& .${classes.menuButton}`]: {
    marginRight: theme.spacing(0),
    color: "#000000",
  },

  [`& .${classes.title}`]: {
    fontWeight: 500,
    lineHeight: 1.6,
    color: "#000000",
    textDecoration: "none",
  },

  [`& .${classes.titleText}`]: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },

  [`&.${classes.navBar}`]: {
    backgroundColor: "#ffffff",
    boxShadow: "rgba(206,206,206,.5) 0 1px 0 0",
    padding: 0,
  },

  [`& .${classes.apple}`]: {
    fontSize: "28px",
    marginRight: "10px",
    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
    },
  },

  [`& .${classes.titleTwo}`]: {
    cursor: "pointer",
    color: "#000000",
    textAlign: "right",
    [theme.breakpoints.down("sm")]: {
      fontSize: ".9rem",
      textAlign: "center",
      margin: "15px 0",
    },
  },

  [`& .${classes.centerMenu}`]: {
    maxWidth: "980px",
    margin: "auto",
    alignItems: "center",
  },

  [`& .${classes.toolBar}`]: {
    minHeight: "52px",
  },

  [`& .${classes.noHover}`]: {
    "&:hover": {
      textDecoration: "none",
    },
  },
}));

interface Props {
  prop?: any;
}

const MenuBar = ({ prop }: Props) => {
  return (
    <StyledAppBar className={classes.navBar} position="static" elevation={0}>
      <Toolbar className={classes.toolBar}>
        <Grid container className={classes.centerMenu}>
          <Grid item xs={12} sm={10}>
            <Link to="/" className={classes.title}>
              <Typography
                variant="h6"
                component="div"
                className={classes.titleText}
              >
                <div className={classes.apple}></div>
                <div>Apple Services Performance Partner Program</div>
              </Typography>
            </Link>
          </Grid>
          <Grid item xs={12} sm={2}>
            <LinkOut
              href="https://performance-partners.apple.com"
              className={classes.noHover}
            >
              <Typography variant="body1" className={classes.titleTwo}>
                Support
              </Typography>
            </LinkOut>
          </Grid>
        </Grid>
      </Toolbar>
    </StyledAppBar>
  );
};

export default MenuBar;

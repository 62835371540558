import React, {
  BaseSyntheticEvent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { observer } from "mobx-react-lite";
import { checkTextValidity, InputFieldProps } from "../formHelpers";
import { FormControl, CircularProgress, FormHelperText } from "@mui/material";
import { BasicHelper, BasicInput, BasicLabel } from "./CustomInputs";
import { reaction } from "mobx";
import { useDebounce } from "use-lodash-debounce";
import { graphql } from "babel-plugin-relay/macro";
import { Environment, fetchQuery } from "react-relay";
import { AffiliateUsernameInputQuery } from "./__generated__/AffiliateUsernameInputQuery.graphql";
import { useRelayEnvironmentContext } from "../../../config/RelayEnvironmentProvider";
import ReactMarkdown from "react-markdown";

const VALIDATE_AFFILIATE_USERNAME_QUERY = graphql`
  query AffiliateUsernameInputQuery($input: UsernameInput!) {
    affiliatePartners {
      validateUsername(input: $input) {
        valid
        errors
      }
    }
  }
`;

const AffiliateUsernameInput: React.FC<InputFieldProps> = observer(
  ({ field, forceShowErrors, disabled, onChange, setValidity }) => {
    const { environment } = useRelayEnvironmentContext();
    const debouncedInput = useDebounce(field.value.data || "", 800);
    const [validationMessage, setValidationMessage] = useState<string>("");
    const [validationMsgColor, setValidationMsgColor] = useState<
      "green" | "red" | ""
    >("");
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [showErrors, setShowErrors] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const checkValidation = useCallback(() => {
      const errors = checkTextValidity(field);

      setErrorMessage(errors.join(" "));
      setValidity(field.key, errors.length === 0);
    }, [field, setValidity]);

    const validateUsername = async () => {
      setIsLoading(true);

      try {
        const response = await fetchQuery<AffiliateUsernameInputQuery>(
          environment as Environment,
          VALIDATE_AFFILIATE_USERNAME_QUERY,
          { input: { username: debouncedInput } },
        ).toPromise();

        const { valid = false, errors = [] } =
          response?.affiliatePartners?.validateUsername || {};
        setIsLoading(false);
        setValidity(field.key, valid);

        // Valid
        if (valid) {
          setValidationMsgColor("green");
          setValidationMessage("Username is available");
          return;
        }

        // Invalid
        setValidationMsgColor("red");
        setShowErrors(true);

        // Display error message(s)
        if (errors.length === 0) {
          setValidationMessage("Username is unavailable");
          return;
        }

        console.error(errors.join(" "));
        const formattedErrors = errors.reduce(
          (prevErrors: string, error: string) => {
            const formattedError = error.split(": ").pop();
            return `${prevErrors}${formattedError} `;
          },
          "",
        );
        setValidationMessage(formattedErrors);
      } catch (error) {
        console.error(error);

        setIsLoading(false);
        setValidationMessage("Error validating username");
        setValidationMsgColor("red");
        setShowErrors(true);
      }
    };

    const handleChange = (e: BaseSyntheticEvent) => {
      // Remove non-alphanumeric characters
      const replacedValue = e.target.value.replace(/[^a-z0-9]/gi, "");
      // Change value only if using alphanumeric characters
      if (replacedValue === e.target.value) {
        setValidationMessage("");
        setValidationMsgColor("");
        onChange(field.key, replacedValue);
      }
    };

    // Debounced value changed -> validate username
    useEffect(() => {
      if (!!debouncedInput) {
        validateUsername();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedInput]);

    // Value changed -> check validation
    useEffect(() => {
      reaction(() => field.value.data, checkValidation);
    }, [field.value, field.data, checkValidation]);

    // Check validation on init and when forceShowErrors updates
    useEffect(() => {
      checkValidation();
    }, [forceShowErrors, checkValidation]);

    // Errors changed
    useEffect(() => {
      const _showErrors = forceShowErrors || field.changed;
      const _hasErrors = errorMessage !== "";
      const _invalidUsername = validationMsgColor === "red";
      setShowErrors((_showErrors && _hasErrors) || _invalidUsername);
    }, [forceShowErrors, errorMessage, field.changed, validationMsgColor]);

    return (
      <>
        <FormControl fullWidth error={showErrors} disabled={disabled}>
          <BasicLabel htmlFor={`${field.displayKey}_id`}>
            {field.name}
          </BasicLabel>
          <BasicInput
            id={`${field.displayKey}_id`}
            onChange={(e: any) => handleChange(e)}
            onBlur={() => (field.changed = true)}
            value={field.value.data || ""}
            placeholder={field.data?.settings?.placeholderText}
          />

          {(errorMessage || field.data?.settings?.helpText) && (
            <FormHelperText component="span">
              <ReactMarkdown linkTarget={"_blank"}>
                {showErrors ? errorMessage : field.data?.settings?.helpText}
              </ReactMarkdown>
            </FormHelperText>
          )}

          {!!isLoading && (
            <CircularProgress size={"1rem"} style={{ marginTop: "5px" }} />
          )}
          {!!validationMessage && (
            <BasicHelper style={{ color: validationMsgColor }}>
              {validationMessage}
            </BasicHelper>
          )}
        </FormControl>
      </>
    );
  },
);

export default AffiliateUsernameInput;

import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { createFragmentContainer, RelayProp } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";
import { VIPPartnerPage_templateToken$data } from "./__generated__/VIPPartnerPage_templateToken.graphql";
import ErrorPage from "./ErrorPage";

import NewSubmission from "../components/NewSubmission";
import { Grid, Typography, Link as LinkOut } from "@mui/material";
const PREFIX = "VIPPartnerPage";

const classes = {
  root: `${PREFIX}-root`,
  header: `${PREFIX}-header`,
  divider: `${PREFIX}-divider`,
  opening: `${PREFIX}-opening`,
  requirements: `${PREFIX}-requirements`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.root}`]: {
    flexGrow: 1,
  },

  [`& .${classes.header}`]: {
    marginBottom: "30px",
  },

  [`& .${classes.divider}`]: {
    margin: "40px 0",
  },

  [`& .${classes.opening}`]: {
    maxWidth: "800px",
    marginTop: "20px",
    fontSize: "21px",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.48,
    letterSpacing: "normal",
    textAlign: "center",
  },

  [`& .${classes.requirements}`]: {
    fontSize: "16px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.63,
    letterSpacing: "0.32px",
    color: "#444444",
  },
}));

interface Props {
  relay: RelayProp;
  templateToken: VIPPartnerPage_templateToken$data;
  router?: any;
  match?: any;
}

type ErrorCopy = {
  headline: string;
  message: string;
  ctaMessage: string;
  ctaLink?: string;
};

const VIPPartnerPage = ({ router, templateToken }: Props) => {
  const [errorCopy, setErrorCopy] = useState<ErrorCopy>();

  useEffect(() => {
    const version = templateToken && templateToken.template.version;
    var error: ErrorCopy = {
      headline: "",
      message: "",
      ctaMessage: "",
      ctaLink: "",
    };

    if (templateToken && !version) {
      error.headline = "Page Not Found";
      error.message = "This page you requested could not be found.";
      error.ctaMessage =
        "If you are working with an Apple contact, reach out to them for an updated link. To apply for the Apple Services Performance Partners Program, visit: ";
      error.ctaLink = "https://partners.applemediaservices.com";
    } else {
      error.headline = "Link Has Expired";
      error.message =
        "The link for this application has either expired, or an application has already been submitted.";
      error.ctaMessage = "Reach out to your Apple contact for a new link.";
    }
    setErrorCopy(error);
  }, [templateToken]);

  return (
    <Root>
      {templateToken && templateToken.template.version ? (
        <NewSubmission
          router={router}
          tagName={templateToken.template.tag?.name}
          version={templateToken.template.version}
          accessToken={templateToken.key}
        >
          <Grid item xs={12} container justifyContent="center" className={classes.header}>
            {templateToken.template.tag?.name === "MLS VIP" ? (
              <Typography className={classes.opening}>
                Register of your invitation MLS program. Once approved, you'll get a link to our
                program on Partnerize where you can add your payment information and get your
                tracking token.
              </Typography>
            ) : (
              <Typography className={classes.opening}>
                Please answer a few questions below. Once we validate the details of your
                application, if we determine you are a good fit, we will contact you and provide
                further details.
              </Typography>
            )}
            <Typography variant="body1" className={classes.requirements}>
              <br />{" "}
              <span>
                View the Apple Services Performance Partner Program terms and conditions{" "}
                <LinkOut href="https://apple.co/pp-partnerizetc">here</LinkOut>
              </span>
            </Typography>
          </Grid>
        </NewSubmission>
      ) : (
        <ErrorPage
          customHeadline={errorCopy?.headline}
          customMessage={errorCopy?.message}
          ctaMessage={errorCopy?.ctaMessage}
          ctaLink={errorCopy?.ctaLink}
        />
      )}
    </Root>
  );
};

export default createFragmentContainer(VIPPartnerPage, {
  templateToken: graphql`
    fragment VIPPartnerPage_templateToken on TemplateToken {
      key
      template {
        id
        name
        type
        slug
        tag {
          name
        }
        version {
          id
          name
          description
          ...NewSubmission_version
        }
      }
    }
  `,
});

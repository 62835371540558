import React from "react";
import { Button } from "@mui/material";

import { useRouter } from "found";

interface Props {
  text: string;
}

const BackButton = ({ text }: Props) => {
  const { router } = useRouter();

  return (
    <Button
      onClick={() => {
        window.scrollTo({
          top: 0,
          behavior: "auto",
        });
        router.go(-1);
      }}>
      {text}
    </Button>
  );
};

export default BackButton;

import React from "react";
import { styled } from "@mui/material/styles";
import {
  Container,
  Typography,
  Link as LinkOut,
  List,
  ListItem,
} from "@mui/material";
const PREFIX = "Landing";

const classes = {
  title: `${PREFIX}-title`,
  list: `${PREFIX}-list`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.title}`]: {
    marginTop: "50px",
    marginBottom: "55px",
    textAlign: "center",
  },

  [`& .${classes.list}`]: {
    listStyleType: "disc !important",
    "& li": {
      display: "list-item",
      marginLeft: theme.spacing(4),
    },
  },
}));

interface Props {
  prop?: any;
}

const Landing = ({ prop }: Props) => {
  return (
    <Root>
      <Container maxWidth="md">
        <Typography className={classes.title} variant="h1">
          Apple Services Performance Partners Program
        </Typography>
      </Container>
      <Container style={{ maxWidth: "70ch" }}>
        <Typography variant="body1">
          Our program is only open to partners who have content on one of the
          Apple Media Stores and by invitation to a limit number of other
          partners.
        </Typography>

        <br />

        <Typography variant="body1">
          If you are a record label, music artist, movie studio, or book
          author/publisher, please apply below:
        </Typography>

        <List className={classes.list}>
          <ListItem>
            <LinkOut href="https://partners.applemediaservices.com/vip/4b18c2dee6255e7de6ce4a2c3c6dc0daa2dd1b902c0c1feca4f883d7ffc3905a">
              Record label or artist
            </LinkOut>
          </ListItem>
          <ListItem>
            <LinkOut href="https://partners.applemediaservices.com/vip/e6b57a081b7dbd02e1290eda06009687caf787bfcd151f801e37acf03f71e0c5">
              Movie or tv studio
            </LinkOut>
          </ListItem>
          <ListItem>
            <LinkOut href="https://partners.applemediaservices.com/vip/caaaa68480bfb15458fc567c4068589e752c43c8989c3479040f7f595cbe66c2">
              Book author or publisher
            </LinkOut>
          </ListItem>
        </List>

        <Typography variant="body1">
          To learn more about the Partner Program, visit our{" "}
          <LinkOut href="https://performance-partners.apple.com/home">
            Affiliate Resources Site.
          </LinkOut>
        </Typography>
      </Container>
    </Root>
  );
};

export default Landing;

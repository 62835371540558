/**
 * @generated SignedSource<<ef65209e5d527eb74dc37f8ca18f96ce>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type formTemplateContentQuery$variables = Record<PropertyKey, never>;
export type formTemplateContentQuery$data = {
  readonly template: {
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"ContentPartnerPage_template">;
  } | null | undefined;
};
export type formTemplateContentQuery = {
  response: formTemplateContentQuery$data;
  variables: formTemplateContentQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "tag",
    "value": "CONTENT"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "formTemplateContentQuery",
    "selections": [
      {
        "alias": null,
        "args": (v0/*: any*/),
        "concreteType": "Template",
        "kind": "LinkedField",
        "name": "template",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ContentPartnerPage_template"
          }
        ],
        "storageKey": "template(tag:\"CONTENT\")"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "formTemplateContentQuery",
    "selections": [
      {
        "alias": null,
        "args": (v0/*: any*/),
        "concreteType": "Template",
        "kind": "LinkedField",
        "name": "template",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "type",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "slug",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Tag",
            "kind": "LinkedField",
            "name": "tag",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TemplateVersion",
            "kind": "LinkedField",
            "name": "version",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "TemplateField",
                "kind": "LinkedField",
                "name": "fields",
                "plural": true,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "key",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "kind",
                    "storageKey": null
                  },
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "parentId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "data",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "displayOrder",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "template(tag:\"CONTENT\")"
      }
    ]
  },
  "params": {
    "cacheID": "efb704e8acb89655b5cbe3bc16e85f1e",
    "id": null,
    "metadata": {},
    "name": "formTemplateContentQuery",
    "operationKind": "query",
    "text": "query formTemplateContentQuery {\n  template(tag: \"CONTENT\") {\n    id\n    ...ContentPartnerPage_template\n  }\n}\n\nfragment ContentPartnerPage_template on Template {\n  id\n  name\n  type\n  slug\n  tag {\n    name\n  }\n  version {\n    id\n    name\n    description\n    ...NewSubmission_version\n  }\n}\n\nfragment NewSubmission_version on TemplateVersion {\n  id\n  name\n  description\n  fields {\n    id\n    key\n    kind\n    name\n    parentId\n    data\n    displayOrder\n  }\n}\n"
  }
};
})();

(node as any).hash = "d8e99b8b4b7670fa31184444464f90cf";

export default node;

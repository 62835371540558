/*

This component requires the animation keyframes to be defined in index.css
I could not figure out how to define them in the spinnerBarStyle
that typescript was happy with (React.CSSProperties typing).

@keyframes spinnerSpin {
  0% { opacity: 1; }
  100% { opacity: 0.15; }
 }
 @-moz-keyframes spinnerSpin {
  0% { opacity: 1; }
  100% { opacity: 0.15; }
 }
 @-webkit-keyframes spinnerSpin {
  0% { opacity: 1; }
  100% { opacity: 0.15; }
 }

*/

import React from "react";
import { Box } from "@mui/material";

const spinnerStyle: React.CSSProperties = {
  position: "relative",
  width: "100%",
  height: "100%",
  zIndex: 999,
};

const spinnerBarStyle: React.CSSProperties = {
  WebkitAnimation: "spinnerSpin 1.2s linear infinite",
  MozAnimation: "spinnerSpin 1.2s linear infinite",
  animation: "spinnerSpin 1.2s linear infinite",
  borderRadius: 5,
  backgroundColor: "black",
  position: "absolute",
  width: "20%",
  height: "7.8%",
  top: "46%",
  left: "40%",
  animationDelay: "",
  WebkitAnimationDelay: "",
  transform: "",
  WebkitTransform: "",
};

type Props = {
  size?: string;
};

const Spinner = ({ size }: Props) => {
  // use the size prop is available, otherwise default to 32px x 32px
  const propStyle = { width: size || "32px", height: size || "32px" };

  //draw bars
  const bars = [];
  for (let i: number = 0; i < 12; i = i + 1) {
    const barStyle = Object.assign({}, spinnerBarStyle);

    barStyle.animationDelay = `${(i - 12) / 10}s`;
    barStyle.WebkitAnimationDelay = barStyle.animationDelay;
    barStyle.transform = `rotate(${i * 30}deg) translate(146%)`;
    barStyle.WebkitTransform = barStyle.transform;
    bars.push(<div style={barStyle} key={i} />);
  }

  return (
    <Box style={propStyle}>
      <Box style={spinnerStyle}>{bars}</Box>
    </Box>
  );
};

export default Spinner;

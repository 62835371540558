import React from "react";
import { useLocalObservable, observer } from "mobx-react-lite";
import {
  FieldSettings,
  initGroupFieldsTree,
  InputFieldProps,
} from "../formHelpers";
import FieldGroup from "../FieldGroup";

//generic address template
export const addressFields: FieldSettings[] = [
  {
    name: "Country",
    key: "country",
    kind: "COUNTRY",
    id: "7",
    parentId: "1",
    data: {
      validation: {
        required: true,
      },
      display: {
        xs: 12,
        md: 6,
      },
      labelName: "Country",
      alwaysShow: true,
      show: true,
    },
    displayOrder: 2,
  },
  {
    name: "Street Address 1",
    key: "line_one",
    kind: "TEXT",
    id: "2",
    parentId: "",
    data: {
      validation: {
        required: true,
      },
      display: {
        xs: 12,
        md: 6,
      },
      labelName: "Street Address 1",
      alwaysShow: true,
      show: true,
    },
    displayOrder: 3,
  },
  {
    name: "Street Address 2",
    key: "line_two",
    kind: "TEXT",
    id: "3",
    parentId: "",
    data: {
      display: {
        xs: 12,
        md: 6,
      },
      labelName: "Street Address 2",
      show: true,
    },
    displayOrder: 4,
  },
  {
    name: "City/Town",
    key: "city",
    kind: "TEXT",
    id: "4",
    parentId: "",
    data: {
      display: {
        xs: 12,
        md: 6,
      },
      labelName: "City/Town",
      show: true,
    },
    displayOrder: 5,
  },
  {
    name: "State/Province/Region",
    key: "state",
    kind: "TEXT",
    id: "5",
    parentId: "",
    data: {
      display: {
        xs: 12,
        md: 6,
      },
      labelName: "State/Province/Region",
      show: true,
    },
    displayOrder: 6,
  },
  {
    name: "Zip/Postal Code",
    key: "zip",
    kind: "TEXT",
    id: "6",
    parentId: "",
    data: {
      validation: {
        required: true,
      },
      display: {
        xs: 12,
        md: 6,
      },
      labelName: "Zip/Postal Code",
      alwaysShow: true,
      show: true,
    },
    displayOrder: 7,
  },
];

const getAddressFields = (fields: FieldSettings[]) => {
  const mappedFields = fields
    .map(({ data, name, ...rest }) => {
      return { ...rest, data, name: data.labelName };
    })
    .filter(({ data: { alwaysShow, show } }) => alwaysShow || show);
  return [
    {
      name: "Container",
      key: "container",
      kind: "CONTAINER",
      id: "1",
      parentId: "",
      data: {
        display: {
          xs: 12,
          md: 12,
        },
      },
    },
    ...mappedFields,
  ];
};

const AddressInput: React.FC<InputFieldProps> = observer(
  ({ field, ...passAlong }) => {
    const fieldsData = field?.data?.settings?.fields
      ? getAddressFields(field.data.settings.fields)
      : addressFields;
    const locals = useLocalObservable(() => ({
      fieldsTree: initGroupFieldsTree(fieldsData, field.value),
    }));

    return (
      <>
        <FieldGroup
          fieldKey={field.key}
          fields={locals.fieldsTree}
          {...passAlong}
        />
      </>
    );
  },
);

export default AddressInput;

import React from "react";
import { styled } from "@mui/material/styles";
import { createFragmentContainer, RelayProp } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";
import { OtherPartnersPage_query$data } from "./__generated__/OtherPartnersPage_query.graphql";
import {
  Typography,
  Grid,
  Divider,
  FormControlLabel,
  RadioGroup,
  Link as LinkOut,
} from "@mui/material";
import CpaDialog from "./CpaDialog";

import { BasicRadio } from "./forms/inputs/CustomInputs";
import BackButton from "./BackButton";

const PREFIX = "OtherPartnersPage";

const classes = {
  root: `${PREFIX}-root`,
  divider: `${PREFIX}-divider`,
  requirements: `${PREFIX}-requirements`,
  requirementBullets: `${PREFIX}-requirementBullets`,
  requirementsBold: `${PREFIX}-requirementsBold`,
  title: `${PREFIX}-title`,
  opening: `${PREFIX}-opening`,
  radioButtons: `${PREFIX}-radioButtons`,
  applyButton: `${PREFIX}-applyButton`,
  applyButtonDisabled: `${PREFIX}-applyButtonDisabled`,
  buttonContainer: `${PREFIX}-buttonContainer`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.root}`]: {
    flexGrow: 1,
  },

  [`& .${classes.divider}`]: {
    margin: "40px 0",
  },

  [`& .${classes.requirements}`]: {
    fontSize: "16px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.63,
    letterSpacing: "0.32px",
    color: "#444444",
  },

  [`& .${classes.requirementBullets}`]: {
    fontSize: "16px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.63,
    letterSpacing: "0.32px",
    color: "#444444",
    margin: "0 0  20px 0",
  },

  [`& .${classes.requirementsBold}`]: {
    fontSize: "16px",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.63,
    letterSpacing: "0.32px",
    color: "#444444",
    fontWeight: 500,
  },

  [`& .${classes.title}`]: {
    marginTop: "50px",
    fontSize: "56px",
    textAlign: "center",
  },

  [`& .${classes.opening}`]: {
    maxWidth: "800px",
    marginTop: "20px",
    fontSize: "21px",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.48,
    letterSpacing: "normal",
    textAlign: "center",
  },

  [`& .${classes.radioButtons}`]: {
    marginRight: "45px",
  },

  [`& .${classes.applyButton}`]: {
    width: "81px",
    height: "35px",
    borderRadius: "4px",
    border: "solid 1px #0177cc",
    backgroundImage: "linear-gradient(to bottom, #409feb, #0270ca 99%)",
    fontSize: "17px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "0.7px",
  },

  [`& .${classes.applyButtonDisabled}`]: {
    marginRight: "13px",
    width: "81px",
    height: "35px",
    borderRadius: "4px",
    border: "solid 1px #0177cc",
    backgroundImage: "linear-gradient(to bottom, #9fcef5, #7ab6e4 99%)",
    fontSize: "17px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "0.7px",
    color: "white",
  },

  [`& .${classes.buttonContainer}`]: {
    margin: "41px 0",
  },
}));

interface Props {
  relay: RelayProp;
  query: OtherPartnersPage_query$data;
  variables: any;
}

const OtherPartnerForms = ({ query }: Props) => {
  const [radioValue, setRadioValue] = React.useState("");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRadioValue((event.target as HTMLInputElement).value);
  };

  const templateRadioControls = () => {
    const templates = query?.templates;

    if (!templates || !templates.edges) {
      throw new Error("assertion failed");
    }

    return templates.edges.map((edge) => {
      const node = edge && edge.node;
      if (!node) throw new Error("assertion failed");

      return (
        <StyledGrid item xs={6} key={node.slug}>
          <FormControlLabel
            key={node.slug}
            className={classes.radioButtons}
            value={node.slug}
            control={<BasicRadio />}
            label={node.name}
            labelPlacement="end"
          />
        </StyledGrid>
      );
    });
  };

  return (
    <Grid container>
      <Grid item xs={12} container justifyContent="center">
        <Typography variant="h1" className={classes.title}>
          Other Partners
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Divider className={classes.divider} />
      </Grid>

      <Grid item container xs={12} spacing={3}>
        <Grid item sm={3} xs={12}>
          <Typography variant="h3">Program Requirements</Typography>
        </Grid>
        <Grid item sm={9} xs={12}>
          <Grid container spacing={3} style={{ padding: "0 10px" }}>
            <Typography className={classes.requirementsBold} variant="body1">
              Baseline requirements for entering the program include:
            </Typography>

            <ul className={classes.requirementBullets}>
              <li>
                Willingness to work on a CPA (cost per acquisition) basis;
                there’s no pay out on CPC (cost per click) or any other payment
                method
              </li>
              <li>
                Proof of working business relationship with Apple Services{" "}
              </li>
              <li>Ability to respond to Apple within 48 hours</li>
              <li>
                Compliance with Apple Services Partner Program{" "}
                <LinkOut href="https://apple.co/pp-partnerizetc">
                  Terms and Conditions
                </LinkOut>
              </li>
            </ul>

            <Typography className={classes.requirementsBold} variant="body1">
              Applications that do the following will not be considered:
            </Typography>

            <ul className={classes.requirementBullets}>
              <li>
                Provide a URL to a website that is still under construction or
                invalid
              </li>
              <li>Appear to not be the legitimate website or property owner</li>
              <li>Do not respond to queries from Apple</li>
              <li>Contain sexually explicit material</li>
              <li>Contain hate, violence, or offensive content</li>
              <li>Promote discrimination</li>
              <li>
                Promote illegal activities or otherwise violates any applicable
                laws
              </li>
              <li>Fail to clearly state a privacy policy to visitors</li>
              <li>Use misleading or fraudulent means to prompt clicks</li>
              <li>Include an Apple trademark in your domain name</li>
              <li>Include other inappropriate elements</li>
            </ul>

            <Typography className={classes.requirements} variant="body1">
              Please note that we will only consider complete applications. Once
              you submit your application to the program, we will review it and
              determine if you are a good fit. If you meet the requirements, we
              will let you know.
            </Typography>

            <Typography variant="body1" className={classes.requirements}>
              <br />{" "}
              <span>
                View the Apple Services Performance Partner Program terms and
                conditions{" "}
                <LinkOut href="https://apple.co/pp-partnerizetc">here</LinkOut>
              </span>
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Divider className={classes.divider} />
      </Grid>

      <Grid container item xs={12}>
        <Grid item xs={12} sm={3}>
          <Typography variant="h3">Your Business</Typography>
        </Grid>
        <Grid item sm={9} xs={12}>
          <Typography className={classes.requirementsBold} variant="h5">
            Select one of the following that best describes you or your
            business:
          </Typography>

          <RadioGroup
            row
            aria-label="business"
            name="business"
            value={radioValue}
            onChange={handleChange}
          >
            {templateRadioControls()}
          </RadioGroup>
        </Grid>
      </Grid>

      <Divider className={classes.divider} />

      <Grid container item xs={12} className={classes.buttonContainer}>
        <Grid item xs={12} md={3}></Grid>
        <Grid item md={3} xs={12}>
          <CpaDialog linkTo={radioValue} />
          <BackButton text="Cancel" />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default createFragmentContainer(OtherPartnerForms, {
  query: graphql`
    fragment OtherPartnersPage_query on Query
    @argumentDefinitions(
      templateTypes: { type: "[TemplateTypeEnum!]", defaultValue: [FORM] }
    ) {
      templates(
        tag: "OTHER"
        types: $templateTypes
        sort: { direction: ASC, field: NAME }
      ) {
        edges {
          node {
            id
            name
            slug
            tag {
              name
            }
          }
        }
      }
    }
  `,
});

import React, { ReactNode } from "react";
import { Field } from "./formHelpers";
import { useSubmissionFormContext } from "./SubmissionForm";
import { observer } from "mobx-react-lite";

type Props = {
  field: Field;
  setValidity: (key: string, valid: boolean) => void;
  children?: ReactNode;
};

// Example rule:
// "rule": {
//   "key": "CHECKBOX_GROUP-06e89vuyv5gt",
//   "action": "SHOW",
//   "valueEquals": "opt-277w0vllf1o"
// }

function getVisibility(rule: any, watchedField?: Field) {
  if (!watchedField) return true;

  if (rule.action === "SHOW") {
    if (watchedField.kind === "CHECKBOX_GROUP") {
      const data = watchedField.value.data || {};
      return data[rule.valueEquals];
    } else {
      return watchedField.value.data === rule.valueEquals;
    }
  }

  return true;
}

const InputVisibility = observer(({ children, field, setValidity }: Props) => {
  const { store } = useSubmissionFormContext();
  const rule = field?.data?.rule || {};
  const watchedField = rule.key ? store.editableFieldsByKey[rule.key] : null;
  const isVisible = getVisibility(rule, watchedField);

  // make a field valid if it is not there
  if (!isVisible) setValidity(field.key, true);

  return <>{isVisible ? children : undefined}</>;
});

export default InputVisibility;

import React from "react";
import { InputFieldProps } from "../formHelpers";
import { Typography, Grid, Box } from "@mui/material";

const ErrorField: React.FC<InputFieldProps> = ({ field }) => {
  return (
    <Grid item xs={12}>
      <Box
        height="50px"
        border="1px solid #f00"
        borderRadius="4px"
        margin="10px 0"
        padding="5px"
      >
        <Typography variant="body2">
          ErrorField - Field kind <b>{field.kind}</b> not found
        </Typography>
      </Box>
    </Grid>
  );
};

export default ErrorField;

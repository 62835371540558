/**
 * @generated SignedSource<<8a9bb7843bc8697591d54e620316b75b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OtherPartnersPage_query$data = {
  readonly templates: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly name: string;
        readonly slug: string;
        readonly tag: {
          readonly name: string;
        } | null | undefined;
      } | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "OtherPartnersPage_query";
};
export type OtherPartnersPage_query$key = {
  readonly " $data"?: OtherPartnersPage_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"OtherPartnersPage_query">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": [
        "FORM"
      ],
      "kind": "LocalArgument",
      "name": "templateTypes"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "OtherPartnersPage_query",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "sort",
          "value": {
            "direction": "ASC",
            "field": "NAME"
          }
        },
        {
          "kind": "Literal",
          "name": "tag",
          "value": "OTHER"
        },
        {
          "kind": "Variable",
          "name": "types",
          "variableName": "templateTypes"
        }
      ],
      "concreteType": "TemplateConnection",
      "kind": "LinkedField",
      "name": "templates",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "TemplateEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Template",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "slug",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Tag",
                  "kind": "LinkedField",
                  "name": "tag",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "d86e63e36c85c5ee2755f20faae2c685";

export default node;

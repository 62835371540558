import React from "react";
import { styled } from "@mui/material/styles";
import { Grid, Container, Typography } from "@mui/material";
const PREFIX = "ApplicationReceived";

const classes = {
  title: `${PREFIX}-title`,
  center: `${PREFIX}-center`,
};

const StyledContainer = styled(Container)(({ theme }) => ({
  [`& .${classes.title}`]: {
    marginTop: "50px",
    marginBottom: "30px",
    textAlign: "center",
  },

  [`& .${classes.center}`]: {
    textAlign: "center",
  },
}));

interface Props {
  prop?: any;
  showVipMessage?: boolean;
}

const ApplicationReceived = (props: Props) => {
  return (
    <StyledContainer maxWidth="md">
      <Grid item xs={12}>
        <Typography className={classes.title} variant="h1">
          Thank you for your interest in the Apple Services Performance Partners
          Program.
        </Typography>
      </Grid>

      <Grid item xs={12}>
        {props.showVipMessage ? (
          <Typography className={classes.center} variant="h3">
            Your application has been received. We will get back to you in 5-7
            days.
          </Typography>
        ) : (
          <Typography className={classes.center} variant="h3">
            Your application has been received. If you qualify, you will hear
            back from us.
          </Typography>
        )}
      </Grid>
    </StyledContainer>
  );
};

export default ApplicationReceived;

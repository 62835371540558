import React, { useCallback, useState } from "react";
import { createFragmentContainer } from "react-relay";
import commitMutationPromise from "../graphQL/mutations/commitMutationPromise";
import { graphql } from "babel-plugin-relay/macro";
import { RelayProp } from "react-relay";
import { NewSubmission_version$data } from "./__generated__/NewSubmission_version.graphql";
import createRenderingMutation from "../graphQL/mutations/createRendering";
import {
  TemplateRenderingCreateInput,
  createRenderingMutation$data,
} from "../graphQL/mutations/__generated__/createRenderingMutation.graphql";
import ErrorPage from "./ErrorPage";

// Material-UI
import { Grid, Typography } from "@mui/material";

// Components
import SubmissionForm from "./forms/SubmissionForm";

interface Props {
  relay: RelayProp;
  version: NewSubmission_version$data;
  tagName?: string;
  accessToken?: string;
  router?: any;
  match?: any;
  children?: any;
  locale?: string | null;
}

enum Errors {
  NO_VERSION = "No template version",
  TOKEN_EXPIRED = "Expires at is invalid",
  TOKEN_EXCEEDED_USAGE = "Max usage is invalid",
  USAGE_LIMIT_REACHED = "Usage limit reached",
}

// Component
const NewSubmission = ({
  relay,
  version,
  tagName,
  accessToken,
  router,
  children,
  locale,
}: Props) => {
  const [errors, setErrors] = useState<string[]>([]);

  const createRendering = useCallback(
    async (input: TemplateRenderingCreateInput) => {
      if (!version) {
        setErrors([Errors.NO_VERSION]);
        return false;
      }
      input.name = version.name || "form";
      input.templateVersionId = version.id;
      if (accessToken) input.accessTokenKey = accessToken;

      try {
        const response: createRenderingMutation$data =
          await commitMutationPromise(relay.environment, {
            mutation: createRenderingMutation,
            variables: {
              input,
            },
          });

        // If there were no errors lets redirect to root
        const errors = response.createPublicRendering.errors.map(
          (e) => e.message,
        );
        if (errors.length === 0) {
          router.push({
            pathname: `/app-received`,
            state: { showVipMessage: tagName === "VIP" },
          });
          return response;
        } else if (
          errors.length === 1 &&
          errors.indexOf(Errors.USAGE_LIMIT_REACHED) > -1
        ) {
          window.location.reload();
        } else {
          setErrors(errors);
        }
      } catch (error) {
        setErrors(["Error received from server."]);
        console.log("Error received from server.", error);
        return error;
      }
    },
    [accessToken, relay.environment, router, tagName, version],
  );

  const errorMessage = () => {
    var errorMessage = "";
    if (accessToken && errors.indexOf(Errors.TOKEN_EXCEEDED_USAGE) > -1) {
      errorMessage = "This access token has already been used.";
    } else if (accessToken && errors.indexOf(Errors.USAGE_LIMIT_REACHED) > -1) {
      errorMessage = "This access token has already been used.";
    } else if (accessToken && errors.indexOf(Errors.TOKEN_EXPIRED) > -1) {
      errorMessage = "This access token has expired.";
    } else if (errors.indexOf(Errors.NO_VERSION)) {
      errorMessage =
        "There is no published version of this template. Please contact an administrator.";
    } else {
      errorMessage = errors[0] || "";
    }
    return errorMessage;
  };

  return (
    <React.Fragment>
      {errors.length === 0 ? (
        <>
          <Grid container spacing={3} style={{ marginTop: "50px" }}>
            <Grid item container xs={12} justifyContent="center">
              <Typography align="center" variant="h1">
                {version.name}
              </Typography>
            </Grid>
            <Grid xs={12} item container>
              {children}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {!version ? (
              <p>No published version to render</p>
            ) : (
              <SubmissionForm
                templateVersion={version}
                onSubmit={createRendering}
                locale={locale}
              />
            )}
          </Grid>
        </>
      ) : (
        <ErrorPage status={403} customMessage={errorMessage()} />
      )}
    </React.Fragment>
  );
};

export default createFragmentContainer(NewSubmission, {
  version: graphql`
    fragment NewSubmission_version on TemplateVersion {
      id
      name
      description
      fields {
        id
        key
        kind
        name
        parentId
        data
        displayOrder
      }
    }
  `,
});

/**
 * @generated SignedSource<<b137535703c55aadfd265bcc01d783e2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type TemplateTypeEnum = "AFTER_EFFECTS" | "BP_PHOTOSHOP" | "FORM" | "IMAGE" | "PHOTOSHOP" | "QR_CODE" | "SCREENSHOT" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type VIPPartnerPage_templateToken$data = {
  readonly key: string;
  readonly template: {
    readonly id: string;
    readonly name: string;
    readonly slug: string;
    readonly tag: {
      readonly name: string;
    } | null | undefined;
    readonly type: TemplateTypeEnum;
    readonly version: {
      readonly description: string | null | undefined;
      readonly id: string;
      readonly name: string | null | undefined;
      readonly " $fragmentSpreads": FragmentRefs<"NewSubmission_version">;
    } | null | undefined;
  };
  readonly " $fragmentType": "VIPPartnerPage_templateToken";
};
export type VIPPartnerPage_templateToken$key = {
  readonly " $data"?: VIPPartnerPage_templateToken$data;
  readonly " $fragmentSpreads": FragmentRefs<"VIPPartnerPage_templateToken">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "VIPPartnerPage_templateToken",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "key",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Template",
      "kind": "LinkedField",
      "name": "template",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "slug",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Tag",
          "kind": "LinkedField",
          "name": "tag",
          "plural": false,
          "selections": [
            (v1/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "TemplateVersion",
          "kind": "LinkedField",
          "name": "version",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "description",
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "NewSubmission_version"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "TemplateToken",
  "abstractKey": null
};
})();

(node as any).hash = "f47965d5f1e464121726c2258fd0fe6b";

export default node;

type Country = {
  name: string,
  alpha2: string,
}

type CountriesByRegion = {
  [region: string]: Country[]
}

const countriesByRegion: CountriesByRegion = {
  "Africa": [
    {"name":"Algeria","alpha2":"DZ"},
    {"name":"Angola","alpha2":"AO"},
    {"name":"Benin","alpha2":"BJ"},
    {"name":"Botswana","alpha2":"BW"},
    {"name":"British Indian Ocean Territory","alpha2":"IO"},
    {"name":"Burkina Faso","alpha2":"BF"},
    {"name":"Burundi","alpha2":"BI"},
    {"name":"Cabo Verde","alpha2":"CV"},
    {"name":"Cameroon","alpha2":"CM"},
    {"name":"Central African Republic","alpha2":"CF"},
    {"name":"Chad","alpha2":"TD"},
    {"name":"Comoros","alpha2":"KM"},
    {"name":"Congo","alpha2":"CG"},
    {"name":"Congo (Democratic Republic of the)","alpha2":"CD"},
    {"name":"Côte d'Ivoire","alpha2":"CI"},
    {"name":"Djibouti","alpha2":"DJ"},
    {"name":"Egypt","alpha2":"EG"},
    {"name":"Equatorial Guinea","alpha2":"GQ"},
    {"name":"Eritrea","alpha2":"ER"},
    {"name":"Ethiopia","alpha2":"ET"},
    {"name":"Gabon","alpha2":"GA"},
    {"name":"Gambia","alpha2":"GM"},
    {"name":"Ghana","alpha2":"GH"},
    {"name":"Guinea","alpha2":"GN"},
    {"name":"Guinea-Bissau","alpha2":"GW"},
    {"name":"Kenya","alpha2":"KE"},
    {"name":"Lesotho","alpha2":"LS"},
    {"name":"Liberia","alpha2":"LR"},
    {"name":"Libya","alpha2":"LY"},
    {"name":"Madagascar","alpha2":"MG"},
    {"name":"Malawi","alpha2":"MW"},
    {"name":"Mali","alpha2":"ML"},
    {"name":"Mauritania","alpha2":"MR"},
    {"name":"Mauritius","alpha2":"MU"},
    {"name":"Mayotte","alpha2":"YT"},
    {"name":"Morocco","alpha2":"MA"},
    {"name":"Mozambique","alpha2":"MZ"},
    {"name":"Namibia","alpha2":"NA"},
    {"name":"Niger","alpha2":"NE"},
    {"name":"Nigeria","alpha2":"NG"},
    {"name":"Rwanda","alpha2":"RW"},
    {"name":"Réunion","alpha2":"RE"},
    {"name":"Saint Helena, Ascension and Tristan da Cunha","alpha2":"SH"},
    {"name":"Sao Tome and Principe","alpha2":"ST"},
    {"name":"Senegal","alpha2":"SN"},
    {"name":"Seychelles","alpha2":"SC"},
    {"name":"Sierra Leone","alpha2":"SL"},
    {"name":"Somalia","alpha2":"SO"},
    {"name":"South Africa","alpha2":"ZA"},
    {"name":"South Sudan","alpha2":"SS"},
    {"name":"Sudan","alpha2":"SD"},
    {"name":"Tanzania, United Republic of","alpha2":"TZ"},
    {"name":"Togo","alpha2":"TG"},
    {"name":"Tunisia","alpha2":"TN"},
    {"name":"Uganda","alpha2":"UG"},
    {"name":"Western Sahara","alpha2":"EH"},
    {"name":"Zambia","alpha2":"ZM"},
    {"name":"Zimbabwe","alpha2":"ZW"},
    {"name":"eSwatini","alpha2":"SZ"}
  ],
  "Americas": [
    {"name":"Anguilla","alpha2":"AI"},
    {"name":"Antigua and Barbuda","alpha2":"AG"},
    {"name":"Argentina","alpha2":"AR"},
    {"name":"Aruba","alpha2":"AW"},
    {"name":"Bahamas","alpha2":"BS"},
    {"name":"Barbados","alpha2":"BB"},
    {"name":"Belize","alpha2":"BZ"},
    {"name":"Bermuda","alpha2":"BM"},
    {"name":"Bolivia (Plurinational State of)","alpha2":"BO"},
    {"name":"Bonaire, Sint Eustatius and Saba","alpha2":"BQ"},
    {"name":"Brazil","alpha2":"BR"},
    {"name":"Canada","alpha2":"CA"},
    {"name":"Cayman Islands","alpha2":"KY"},
    {"name":"Chile","alpha2":"CL"},
    {"name":"Colombia","alpha2":"CO"},
    {"name":"Costa Rica","alpha2":"CR"},
    {"name":"Cuba","alpha2":"CU"},
    {"name":"Curaçao","alpha2":"CW"},
    {"name":"Dominica","alpha2":"DM"},
    {"name":"Dominican Republic","alpha2":"DO"},
    {"name":"Ecuador","alpha2":"EC"},
    {"name":"El Salvador","alpha2":"SV"},
    {"name":"Falkland Islands (Malvinas)","alpha2":"FK"},
    {"name":"French Guiana","alpha2":"GF"},
    {"name":"Greenland","alpha2":"GL"},
    {"name":"Grenada","alpha2":"GD"},
    {"name":"Guadeloupe","alpha2":"GP"},
    {"name":"Guatemala","alpha2":"GT"},
    {"name":"Guyana","alpha2":"GY"},
    {"name":"Haiti","alpha2":"HT"},
    {"name":"Honduras","alpha2":"HN"},
    {"name":"Jamaica","alpha2":"JM"},
    {"name":"Martinique","alpha2":"MQ"},
    {"name":"Mexico","alpha2":"MX"},
    {"name":"Montserrat","alpha2":"MS"},
    {"name":"Nicaragua","alpha2":"NI"},
    {"name":"Panama","alpha2":"PA"},
    {"name":"Paraguay","alpha2":"PY"},
    {"name":"Peru","alpha2":"PE"},
    {"name":"Puerto Rico","alpha2":"PR"},
    {"name":"Saint Barthélemy","alpha2":"BL"},
    {"name":"Saint Kitts and Nevis","alpha2":"KN"},
    {"name":"Saint Lucia","alpha2":"LC"},
    {"name":"Saint Martin (French part)","alpha2":"MF"},
    {"name":"Saint Pierre and Miquelon","alpha2":"PM"},
    {"name":"Saint Vincent and the Grenadines","alpha2":"VC"},
    {"name":"Sint Maarten (Dutch part)","alpha2":"SX"},
    {"name":"South Georgia and the South Sandwich Islands","alpha2":"GS"},
    {"name":"Suriname","alpha2":"SR"},
    {"name":"Trinidad and Tobago","alpha2":"TT"},
    {"name":"Turks and Caicos Islands","alpha2":"TC"},
    {"name":"United States Minor Outlying Islands","alpha2":"UM"},
    {"name":"United States of America","alpha2":"US"},
    {"name":"Uruguay","alpha2":"UY"},
    {"name":"Venezuela (Bolivarian Republic of)","alpha2":"VE"},
    {"name":"Virgin Islands (British)","alpha2":"VG"},
    {"name":"Virgin Islands (U.S.)","alpha2":"VI"}
  ],
  "Asia": [
    {"name":"Afghanistan","alpha2":"AF"},
    {"name":"Armenia","alpha2":"AM"},
    {"name":"Azerbaijan","alpha2":"AZ"},
    {"name":"Bahrain","alpha2":"BH"},
    {"name":"Bangladesh","alpha2":"BD"},
    {"name":"Bhutan","alpha2":"BT"},
    {"name":"Brunei Darussalam","alpha2":"BN"},
    {"name":"Cambodia","alpha2":"KH"},
    {"name":"China","alpha2":"CN"},
    {"name":"Cyprus","alpha2":"CY"},
    {"name":"Georgia","alpha2":"GE"},
    {"name":"Hong Kong","alpha2":"HK"},
    {"name":"India","alpha2":"IN"},
    {"name":"Indonesia","alpha2":"ID"},
    {"name":"Iran (Islamic Republic of)","alpha2":"IR"},
    {"name":"Iraq","alpha2":"IQ"},
    {"name":"Israel","alpha2":"IL"},
    {"name":"Japan","alpha2":"JP"},
    {"name":"Jordan","alpha2":"JO"},
    {"name":"Kazakhstan","alpha2":"KZ"},
    {"name":"Korea (Democratic People's Republic of)","alpha2":"KP"},
    {"name":"Korea (Republic of)","alpha2":"KR"},
    {"name":"Kuwait","alpha2":"KW"},
    {"name":"Kyrgyzstan","alpha2":"KG"},
    {"name":"Lao People's Democratic Republic","alpha2":"LA"},
    {"name":"Lebanon","alpha2":"LB"},
    {"name":"Macao","alpha2":"MO"},
    {"name":"Malaysia","alpha2":"MY"},
    {"name":"Maldives","alpha2":"MV"},
    {"name":"Mongolia","alpha2":"MN"},
    {"name":"Myanmar","alpha2":"MM"},
    {"name":"Nepal","alpha2":"NP"},
    {"name":"Oman","alpha2":"OM"},
    {"name":"Pakistan","alpha2":"PK"},
    {"name":"Palestine, State of","alpha2":"PS"},
    {"name":"Philippines","alpha2":"PH"},
    {"name":"Qatar","alpha2":"QA"},
    {"name":"Saudi Arabia","alpha2":"SA"},
    {"name":"Singapore","alpha2":"SG"},
    {"name":"Sri Lanka","alpha2":"LK"},
    {"name":"Syrian Arab Republic","alpha2":"SY"},
    {"name":"Taiwan, Province of China","alpha2":"TW"},
    {"name":"Tajikistan","alpha2":"TJ"},
    {"name":"Thailand","alpha2":"TH"},
    {"name":"Timor-Leste","alpha2":"TL"},
    {"name":"Turkey","alpha2":"TR"},
    {"name":"Turkmenistan","alpha2":"TM"},
    {"name":"United Arab Emirates","alpha2":"AE"},
    {"name":"Uzbekistan","alpha2":"UZ"},
    {"name":"Viet Nam","alpha2":"VN"},
    {"name":"Yemen","alpha2":"YE"}
  ],
  "Europe": [
    {"name":"Albania","alpha2":"AL"},
    {"name":"Andorra","alpha2":"AD"},
    {"name":"Austria","alpha2":"AT"},
    {"name":"Belarus","alpha2":"BY"},
    {"name":"Belgium","alpha2":"BE"},
    {"name":"Bosnia and Herzegovina","alpha2":"BA"},
    {"name":"Bulgaria","alpha2":"BG"},
    {"name":"Croatia","alpha2":"HR"},
    {"name":"Czech Republic","alpha2":"CZ"},
    {"name":"Denmark","alpha2":"DK"},
    {"name":"Estonia","alpha2":"EE"},
    {"name":"Faroe Islands","alpha2":"FO"},
    {"name":"Finland","alpha2":"FI"},
    {"name":"France","alpha2":"FR"},
    {"name":"Germany","alpha2":"DE"},
    {"name":"Gibraltar","alpha2":"GI"},
    {"name":"Greece","alpha2":"GR"},
    {"name":"Guernsey","alpha2":"GG"},
    {"name":"Holy See","alpha2":"VA"},
    {"name":"Hungary","alpha2":"HU"},
    {"name":"Iceland","alpha2":"IS"},
    {"name":"Ireland","alpha2":"IE"},
    {"name":"Isle of Man","alpha2":"IM"},
    {"name":"Italy","alpha2":"IT"},
    {"name":"Jersey","alpha2":"JE"},
    {"name":"Latvia","alpha2":"LV"},
    {"name":"Liechtenstein","alpha2":"LI"},
    {"name":"Lithuania","alpha2":"LT"},
    {"name":"Luxembourg","alpha2":"LU"},
    {"name":"Malta","alpha2":"MT"},
    {"name":"Moldova (Republic of)","alpha2":"MD"},
    {"name":"Monaco","alpha2":"MC"},
    {"name":"Montenegro","alpha2":"ME"},
    {"name":"Netherlands","alpha2":"NL"},
    {"name":"North Macedonia","alpha2":"MK"},
    {"name":"Norway","alpha2":"NO"},
    {"name":"Poland","alpha2":"PL"},
    {"name":"Portugal","alpha2":"PT"},
    {"name":"Romania","alpha2":"RO"},
    {"name":"Russian Federation","alpha2":"RU"},
    {"name":"San Marino","alpha2":"SM"},
    {"name":"Serbia","alpha2":"RS"},
    {"name":"Slovakia","alpha2":"SK"},
    {"name":"Slovenia","alpha2":"SI"},
    {"name":"Spain","alpha2":"ES"},
    {"name":"Svalbard and Jan Mayen","alpha2":"SJ"},
    {"name":"Sweden","alpha2":"SE"},
    {"name":"Switzerland","alpha2":"CH"},
    {"name":"Ukraine","alpha2":"UA"},
    {"name":"United Kingdom of Great Britain and Northern Ireland","alpha2":"GB"},
    {"name":"Åland Islands","alpha2":"AX"}
  ],
  "Oceania": [
    {"name":"American Samoa","alpha2":"AS"},
    {"name":"Australia","alpha2":"AU"},
    {"name":"Christmas Island","alpha2":"CX"},
    {"name":"Cocos (Keeling) Islands","alpha2":"CC"},
    {"name":"Cook Islands","alpha2":"CK"},
    {"name":"Fiji","alpha2":"FJ"},
    {"name":"French Polynesia","alpha2":"PF"},
    {"name":"Guam","alpha2":"GU"},
    {"name":"Kiribati","alpha2":"KI"},
    {"name":"Marshall Islands","alpha2":"MH"},
    {"name":"Micronesia (Federated States of)","alpha2":"FM"},
    {"name":"Nauru","alpha2":"NR"},
    {"name":"New Caledonia","alpha2":"NC"},
    {"name":"New Zealand","alpha2":"NZ"},
    {"name":"Niue","alpha2":"NU"},
    {"name":"Norfolk Island","alpha2":"NF"},
    {"name":"Northern Mariana Islands","alpha2":"MP"},
    {"name":"Palau","alpha2":"PW"},
    {"name":"Papua New Guinea","alpha2":"PG"},
    {"name":"Pitcairn","alpha2":"PN"},
    {"name":"Samoa","alpha2":"WS"},
    {"name":"Solomon Islands","alpha2":"SB"},
    {"name":"Tokelau","alpha2":"TK"},
    {"name":"Tonga","alpha2":"TO"},
    {"name":"Tuvalu","alpha2":"TV"},
    {"name":"Vanuatu","alpha2":"VU"},
    {"name":"Wallis and Futuna","alpha2":"WF"}
  ],
  "Other": [
    {"name":"Antarctica","alpha2":"AQ"},
    {"name":"Bouvet Island","alpha2":"BV"},
    {"name":"French Southern Territories","alpha2":"TF"},
    {"name":"Heard Island and McDonald Islands","alpha2":"HM"}
  ]
};

export default countriesByRegion;

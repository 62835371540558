import { ReactNode, useEffect } from "react";
import { useRouter } from "found";

type Props = {
  children?: ReactNode;
};

const ScrollUp = ({ children }: Props) => {
  const { match } = useRouter();
  const location = match.location.pathname;

  useEffect(() => {
    setTimeout(() => {
      window.scroll(0, 0);
    }, 1);
  }, [location]);

  return null;
};

export default ScrollUp;

import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { reaction } from "mobx";
import { observer } from "mobx-react-lite";
import { checkMultiOptionsValidity, InputFieldProps } from "../formHelpers";
import {
  FormControl,
  MenuItem,
  ListSubheader,
  Chip,
  FormHelperText,
} from "@mui/material";

import countriesByRegion from "./data/countries";
import { BasicSelect, BasicLabel } from "./CustomInputs";
import ReactMarkdown from "react-markdown";

const PREFIX = "CountrySelectInput";

const classes = {
  regionHeading: `${PREFIX}-regionHeading`,
  chips: `${PREFIX}-chips`,
  chip: `${PREFIX}-chip`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.regionHeading}`]: {
    backgroundColor: theme.palette.grey[100],
  },

  [`& .${classes.chips}`]: {
    display: "flex",
    flexWrap: "wrap",
  },

  [`& .${classes.chip}`]: {
    margin: 2,
  },
}));

export type Option = {
  label: string;
  value: string;
};

const countriesByCode: any = {};
Object.keys(countriesByRegion).forEach((region: string) => {
  countriesByRegion[region].forEach((country) => {
    countriesByCode[country.alpha2] = country.name;
  });
});

const CountrySelectInput: React.FC<InputFieldProps> = observer(
  ({ field, forceShowErrors, disabled, onChange, setValidity }) => {
    const [errorMessage, setErrorMessage] = useState("");
    const [showErrors, setShowErrors] = useState(false);

    if (!field.value.data) {
      field.value.data = [];
    }

    const checkValidation = () => {
      // check text validations
      const errors = checkMultiOptionsValidity(field);
      // set error messages
      setErrorMessage(errors.join(" "));
      setValidity(field.key, errors.length === 0);
    };

    // weird syntax to do watch like in vue
    useEffect(() => {
      reaction(() => field.value.data, checkValidation);
      if (!field.data?.validation?.required) checkValidation();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // check validation on init and when forceShowErrors updates
    useEffect(() => {
      checkValidation();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [forceShowErrors]);

    useEffect(() => {
      setShowErrors((forceShowErrors || field.changed) && errorMessage !== "");
    }, [forceShowErrors, errorMessage, field.changed]);

    // map options for select
    const selectOptions = Object.keys(countriesByRegion).map(
      (region: string) => {
        const header = (
          <ListSubheader key={region} className={classes.regionHeading}>
            {region}
          </ListSubheader>
        );

        const items = countriesByRegion[region].map((country) => {
          return (
            <MenuItem key={country.alpha2} value={country.alpha2}>
              {country.name}
            </MenuItem>
          );
        });

        return [header, ...items];
      },
    );

    // render component
    return (
      <Root>
        <FormControl
          disabled={disabled}
          variant="outlined"
          fullWidth
          error={showErrors}
        >
          <BasicLabel htmlFor={`${field.displayKey}_id`}>
            {field.name}
          </BasicLabel>
          <BasicSelect
            id={`${field.displayKey}_id`}
            multiple
            value={field.value.data}
            onChange={(e: any) => {
              const value = e.target.value;

              if (value[value.length - 1]) {
                field.changed = true;
                onChange(field.key, value);
              }
            }}
            renderValue={(selected: any) => (
              <div className={classes.chips}>
                {(selected as string[]).map((value) => (
                  <Chip
                    key={value}
                    label={countriesByCode[value]}
                    className={classes.chip}
                  />
                ))}
              </div>
            )}
          >
            <MenuItem value="">
              {field.data?.settings?.placeholderText}
            </MenuItem>
            {selectOptions}
          </BasicSelect>
          <FormHelperText component="span">
            <ReactMarkdown linkTarget={"_blank"}>
              {showErrors ? errorMessage : field.data?.settings?.helpText}
            </ReactMarkdown>
          </FormHelperText>
        </FormControl>
      </Root>
    );
  },
);

export default CountrySelectInput;

import { graphql } from "babel-plugin-relay/macro";

const createRendering = graphql`
  mutation createRenderingMutation($input: TemplateRenderingCreateInput!) {
    createPublicRendering(input: $input) {
      saved
      errors {
        message
      }
    }
  }
`;

export default createRendering

import React from "react";
import { Typography, Container, Box, Link as LinkOut } from "@mui/material";

interface Props {
  status?: number;
  customHeadline?: string;
  customMessage?: string;
  ctaMessage?: string;
  ctaLink?: string;
}

const ErrorPage: React.FC<Props> = ({
  status,
  customHeadline,
  customMessage,
  ctaMessage,
  ctaLink,
}) => {
  let message = "";
  let headline = "";

  switch (status) {
    case 403:
      headline = "Access Denied";
      message = "Please navigate back to the home page.";
      break;

    case 404:
      headline = "404 Page Not Found";
      message =
        "Requested page could not be found, please navigate back to the home page.";
      break;

    case 500:
      headline = "500 Something went wrong";
      message = "Please navigate back to the home page.";
      break;

    default:
      headline = "500 Something went wrong";
      message = "Please navigate back to the home page.";
      break;
  }

  return (
    <Container maxWidth="lg" style={{ padding: "100px 20px 40px 20px" }}>
      <Box textAlign="center" mt={8}>
        <Typography variant="h1">
          {customHeadline ? customHeadline : headline}
        </Typography>
        <br />
        <br />
        <Typography variant="subtitle1">
          {customMessage ? customMessage : message}
        </Typography>
        <br />
        <br />
        <Typography variant="subtitle1">
          {ctaMessage ? ctaMessage : ""}
        </Typography>
        {ctaLink ? (
          <Typography variant="subtitle1">
            <LinkOut href={ctaLink}>{ctaLink}</LinkOut>
          </Typography>
        ) : null}
      </Box>
    </Container>
  );
};

export default ErrorPage;

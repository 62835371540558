import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import { toJS, reaction } from "mobx";
import { useLocalObservable, observer } from "mobx-react-lite";
import { initGroupFieldsTree, InputFieldProps, Field } from "../formHelpers";
import { Grid, Box, FormHelperText } from "@mui/material";
import FieldGroup from "../FieldGroup";
import ReactMarkdown from "react-markdown";

const PREFIX = "NestedAttributesInput";

const classes = {
  addBox: `${PREFIX}-addBox`,
  circle: `${PREFIX}-circle`,
  plus: `${PREFIX}-plus`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.addBox}`]: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    color: "#206ecc",
    cursor: "pointer",
    fontFamily: "SF Pro Display",
    fontSize: "15px",
  },

  [`& .${classes.circle}`]: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    border: "1px solid #006ecd",
    width: "18px",
    height: "18px",
    borderRadius: "50%",
    marginRight: "10px",
  },

  [`& .${classes.plus}`]: {
    marginTop: "-3px",
  },
}));

function flattenFields(fieldsTree?: Field[], result: Field[] = []) {
  if (!fieldsTree) return result;

  fieldsTree.forEach((field: Field) => {
    if (field.kind === "NESTED_ATTRIBUTES") return;

    result.push(field);

    if (field.fields) {
      flattenFields(field.fields, result);
    }
  });

  return result;
}

function initNestedFields(fields: any, valueData: any, fieldId: string) {
  const initialData = valueData ? valueData : [{}];

  const flattenedFields = flattenFields(fields);
  return initialData.map((data: any, index: number) => {
    return {
      fields: initGroupFieldsTree(
        toJS(flattenedFields),
        { data },
        fieldId,
        index,
      ),
      value: data,
      valid: false,
    };
  });
}

const NestedAttributesInput: React.FC<InputFieldProps> = observer(
  ({ field, forceShowErrors, disabled, onChange, setValidity }) => {
    const source = field;
    const locals = useLocalObservable(() => ({
      flattenedFields: flattenFields(source.fields),
      nestedFields: initNestedFields(
        source.fields,
        source.value.data,
        source.id,
      ),
      addNestedFields() {
        this.nestedFields.push({
          fields: initGroupFieldsTree(
            toJS(this.flattenedFields),
            {},
            source.id,
            this.nestedFields.length,
          ),
          value: {},
          valid: false,
        });
      },
      updateField(index: string, data: any) {
        const nestedField = this.nestedFields[index];
        if (nestedField) nestedField.value = data;
      },
      updateValidity(index: string, valid: boolean) {
        const nestedField = this.nestedFields[parseInt(index)];
        if (nestedField) nestedField.valid = valid;
      },
      get valid() {
        const invalidFields = this.nestedFields.filter(
          (field: any) => !field.valid,
        );
        return invalidFields.length === 0;
      },
      get value() {
        return this.nestedFields.map((field: any) => field.value);
      },
    }));

    const sendValidity = () => {
      setValidity(field.key, locals.valid);
    };

    const sendValue = () => {
      onChange(field.key, locals.value);
    };

    const settings = field.data?.settings || {};

    // weird syntax to do watch like in vue
    useEffect(() => {
      reaction(() => locals.valid, sendValidity);
      reaction(() => locals.value, sendValue);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      sendValidity();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [forceShowErrors]);

    return (
      <Root>
        <Grid container spacing={3}>
          {locals.nestedFields.map((nestedGroup: any, index: number) => {
            return (
              <Grid key={index} item xs={12}>
                <FieldGroup
                  fieldKey={index.toString()}
                  fields={nestedGroup.fields}
                  forceShowErrors={forceShowErrors}
                  disabled={disabled}
                  onChange={locals.updateField}
                  setValidity={locals.updateValidity}
                />
              </Grid>
            );
          })}
          {settings.addable && (
            <Grid item xs={12}>
              <Box
                className={classes.addBox}
                onClick={() => {
                  locals.addNestedFields();
                }}
              >
                <span className={classes.circle}>
                  <span className={classes.plus}>+</span>
                </span>
                {settings.addableText}
              </Box>
            </Grid>
          )}
          <Grid item xs={12}>
            <FormHelperText component="span">
              <ReactMarkdown linkTarget={"_blank"}>
                {field.data?.settings?.helpText}
              </ReactMarkdown>
            </FormHelperText>
          </Grid>
        </Grid>
      </Root>
    );
  },
);

export default NestedAttributesInput;

import React from "react";
import { useRelayEnvironmentContext } from "./RelayEnvironmentProvider";

import { BrowserProtocol, queryMiddleware } from "farce";
import { createFarceRouter, createRender } from "found";

import routes from "./routes";
import ErrorPage from "../components/ErrorPage";

const Router = createFarceRouter({
  historyProtocol: new BrowserProtocol(),
  historyMiddlewares: [queryMiddleware],
  routeConfig: routes,
  render: createRender({}),
  renderError: ({ error }) => {
    return <ErrorPage status={error.status || 500} />;
  },
});

const RelayRouter = (props: any) => {
  const { resolver } = useRelayEnvironmentContext();

  return <Router resolver={resolver} />;
};

export default RelayRouter;

import React from "react";
import { FormHelperText, Grid } from "@mui/material";
import FieldContainer from "../FieldContainer";
import { InputFieldProps } from "../formHelpers";
import ReactMarkdown from "react-markdown";

const ContainerWrapper: React.FC<InputFieldProps> = ({
  field,
  ...passAlong
}) => {
  return (
    <>
      <Grid container spacing={3}>
        <FieldContainer field={field} {...passAlong} />
        <Grid item xs={12}>
          <FormHelperText component="span">
            <ReactMarkdown linkTarget={"_blank"}>
              {field.data?.settings?.helpText}
            </ReactMarkdown>
          </FormHelperText>
        </Grid>
      </Grid>
    </>
  );
};

export default ContainerWrapper;

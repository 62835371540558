import {
  InputBase,
  FormLabel,
  FormHelperText,
  Radio,
  Checkbox,
  Select,
  styled,
} from "@mui/material";

export const BasicInput = styled(InputBase)(({ theme }) => ({
  root: {
    "label + &": {},
    "&.Mui-error": {
      "& .MuiInputBase-input": {
        borderColor: theme.palette.error.main,
      },
    },
  },
  input: {
    width: "100%",
    borderRadius: 3,
    position: "relative",
    backgroundColor: theme.palette.common.white,
    border: "1px solid #ccc",
    fontSize: 16,
    padding: "8px 6px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&::placeholder": {
      color: "#888888",
      fontSize: "16px",
    },
    "&:focus": {
      borderColor: theme.palette.primary.main,
      borderRadius: 4,
      backgroundColor: theme.palette.common.white,
    },
    "& + svg": {
      top: "auto",
    },
  },
}));

export const BasicSelect = styled(Select)(({ theme }) => ({
  root: {
    "&.Mui-error": {
      "& .MuiInputBase-input": {
        borderColor: theme.palette.error.main,
      },
    },
  },
  select: {
    width: "100%",
    borderRadius: 3,
    position: "relative",
    backgroundColor: theme.palette.common.white,
    fontSize: 16,
    padding: "8px 6px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      borderColor: theme.palette.primary.main,
      borderRadius: 4,
      backgroundColor: theme.palette.common.white,
    },
    "& + svg": {
      top: "auto",
    },
  },
}));

export const BasicRadio = styled(Radio)(({ theme }) => ({
  root: {
    color: "#ccc",
    "&&:hover": {
      backgroundColor: "transparent",
    },
    "&$checked": {
      color: "#27a33f",
    },
    "&.Mui-error": {
      "& .MuiInputBase-input": {
        borderColor: theme.palette.error.main,
      },
    },
  },
  checked: {
    color: "#27a33f",
  },
  disabled: {},
}));

export const BasicCheckbox = styled(Checkbox)(({ theme }) => ({
  root: {
    color: "#ccc",
    "&&:hover": {
      backgroundColor: "transparent",
    },
    "&$checked": {
      color: "#27a33f",
    },
    "&.Mui-error": {
      "& .MuiInputBase-input": {
        borderColor: theme.palette.error.main,
      },
    },
  },
  checked: {
    color: "#27a33f",
  },
  disabled: {},
}));

export const BasicLabel = styled(FormLabel)(({ theme }) => ({
  root: {
    color: "#444444",
    lineHeight: "1.3",
    marginBottom: "10px",
    minHeight: "1.3em",
    fontFamily: "SF Pro Display",
    "&.Mui-focused": {
      color: "#444444",
      "&.Mui-error": {
        color: theme.palette.error.main,
      },
    },
  },
}));

export const BasicHelper = styled(FormHelperText)(({ theme }) => ({
  root: {
    fontFamily: "SF Pro Display",
    fontStyle: "normal",
    fontWeight: 400,
    color: "#9b9b9b",
    marginLeft: 0,
    "&.Mui-focused": {
      color: "#9b9b9b",
      "&.Mui-error": {
        color: theme.palette.error.main,
      },
    },
  },
}));

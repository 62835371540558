import React from "react";
import { styled } from "@mui/material/styles";
import { createFragmentContainer, RelayProp } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";
import { ContentPartnerPage_template$data } from "./__generated__/ContentPartnerPage_template.graphql";

import NewSubmission from "../components/NewSubmission";
import { Grid, Typography, Link as LinkOut } from "@mui/material";
import ErrorPage from "./ErrorPage";

const PREFIX = "JapanesePartnerPage";

const classes = {
  root: `${PREFIX}-root`,
  divider: `${PREFIX}-divider`,
  opening: `${PREFIX}-opening`,
  requirements: `${PREFIX}-requirements`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.root}`]: {
    flexGrow: 1,
  },

  [`& .${classes.divider}`]: {
    margin: "40px 0",
  },

  [`& .${classes.opening}`]: {
    maxWidth: "800px",
    marginTop: "20px",
    fontSize: "21px",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.48,
    letterSpacing: "normal",
    textAlign: "center",
  },

  [`& .${classes.requirements}`]: {
    fontSize: "16px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.63,
    letterSpacing: "0.32px",
    color: "#444444",
  },
}));

interface Props {
  relay: RelayProp;
  template: ContentPartnerPage_template$data;
  router?: any;
  match?: any;
}

const JapanesePartnerPage = ({ router, template }: Props) => {
  return (
    <Root>
      {template?.version ? (
        <NewSubmission
          router={router}
          tagName={template.tag?.name}
          version={template.version}
          locale="ja-JP"
        >
          <Grid item xs={12} container justifyContent="center">
            <Typography className={classes.opening}>
              以下の質問にご回答をお願いいたします。申請いただいた内容を審査した上、アフィリエイトアカウント登録用のリンクをお送りいたします。アカウントご登録いただき次第、アフィリエイトリンク作成用のツールとリソースをご共有いたします。
            </Typography>
            <Typography variant="body1" className={classes.requirements}>
              <br />{" "}
              <span>
                Apple Services Performance Partner Programの利用規約は
                <LinkOut href="https://apple.co/pp-partnerizetc">
                  こちら
                </LinkOut>
                からご確認ください。
              </span>
            </Typography>
          </Grid>
        </NewSubmission>
      ) : (
        //TODO ensure this never happens or handle this in a elegant public facing way
        <ErrorPage status={404} />
      )}
    </Root>
  );
};

export default createFragmentContainer(JapanesePartnerPage, {
  template: graphql`
    fragment JapanesePartnerPage_template on Template {
      id
      name
      type
      slug
      tag {
        name
      }
      version {
        id
        name
        description
        ...NewSubmission_version
      }
    }
  `,
});

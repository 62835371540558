/**
 * @generated SignedSource<<e0652fca010f8672ca4fd4eaf3869ab9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TemplateTypeEnum = "AFTER_EFFECTS" | "BP_PHOTOSHOP" | "FORM" | "IMAGE" | "PHOTOSHOP" | "QR_CODE" | "SCREENSHOT" | "%future added value";
export type formTemplatesOtherQuery$variables = {
  templateTypes?: ReadonlyArray<TemplateTypeEnum> | null | undefined;
};
export type formTemplatesOtherQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"OtherPartnersPage_query">;
};
export type formTemplatesOtherQuery = {
  response: formTemplatesOtherQuery$data;
  variables: formTemplatesOtherQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "templateTypes"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "formTemplatesOtherQuery",
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "templateTypes",
            "variableName": "templateTypes"
          }
        ],
        "kind": "FragmentSpread",
        "name": "OtherPartnersPage_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "formTemplatesOtherQuery",
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "sort",
            "value": {
              "direction": "ASC",
              "field": "NAME"
            }
          },
          {
            "kind": "Literal",
            "name": "tag",
            "value": "OTHER"
          },
          {
            "kind": "Variable",
            "name": "types",
            "variableName": "templateTypes"
          }
        ],
        "concreteType": "TemplateConnection",
        "kind": "LinkedField",
        "name": "templates",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TemplateEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Template",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "slug",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Tag",
                    "kind": "LinkedField",
                    "name": "tag",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "50d007a29ca526e33b552ca0873fcd36",
    "id": null,
    "metadata": {},
    "name": "formTemplatesOtherQuery",
    "operationKind": "query",
    "text": "query formTemplatesOtherQuery(\n  $templateTypes: [TemplateTypeEnum!]\n) {\n  ...OtherPartnersPage_query_1NNc2Y\n}\n\nfragment OtherPartnersPage_query_1NNc2Y on Query {\n  templates(tag: \"OTHER\", types: $templateTypes, sort: {direction: ASC, field: NAME}) {\n    edges {\n      node {\n        id\n        name\n        slug\n        tag {\n          name\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d5c6a98fb5dd75dab893a038d0ca80c7";

export default node;

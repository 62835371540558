import React from "react";
import { styled } from "@mui/material/styles";
import { Grid, Container, Typography, Link } from "@mui/material";
const PREFIX = "Footer";

const classes = {
  footer: `${PREFIX}-footer`,
  container: `${PREFIX}-container`,
  list: `${PREFIX}-list`,
  listItem: `${PREFIX}-listItem`,
  left: `${PREFIX}-left`,
  right: `${PREFIX}-right`,
  link: `${PREFIX}-link`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.footer}`]: {
    marginTop: "30px",
    width: "100%",
    padding: "52px 0",
    backgroundColor: "#fafafa",
    color: "#9b9b9b",
  },

  [`& .${classes.container}`]: {
    alignItems: "center",
  },

  [`& .${classes.list}`]: {
    listStyle: "none",
    paddingLeft: 0,
    textAlign: "center",
  },

  [`& .${classes.listItem}`]: {
    display: "inline-block",
    borderRight: "1px solid #9b9b9b",
    paddingRight: "10px",
    margin: "5px 10px 0 0",
    whiteSpace: "nowrap",

    "&:last-child": {
      paddingRight: 0,
      marginRight: 0,
      borderRight: "none",
    },
  },

  [`& .${classes.left}`]: {
    justifyContent: "flex-start",
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    },
  },

  [`& .${classes.right}`]: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    },
  },

  [`& .${classes.link}`]: {
    color: "#9b9b9b",
    textDecoration: "none",
    fontSize: "0.875rem",
    fontWeight: 400,
    lineHeight: 1.43,
    cursor: "pointer",
    "&:hover": {
      textDecoration: "none",
    },
  },
}));

const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <StyledGrid className={classes.footer}>
      <Container maxWidth="lg">
        <Grid container className={classes.container}>
          <Grid item xs={12} md={6} className={classes.left}>
            <Typography variant="body2">
              Copyright {year} Apple Inc. All rights reserved.
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} className={classes.right}>
            <ul className={classes.list}>
              <li className={classes.listItem}>
                <Link
                  className={classes.link}
                  href="https://www.apple.com/apple-music/"
                >
                  Apple Music
                </Link>
              </li>

              <li className={classes.listItem}>
                <Link
                  className={classes.link}
                  href="https://www.apple.com/apple-tv-plus/"
                >
                  Apple TV +
                </Link>
              </li>

              <li className={classes.listItem}>
                <Link
                  className={classes.link}
                  href="https://www.apple.com/apple-news/"
                >
                  Apple News+
                </Link>
              </li>

              <li className={classes.listItem}>
                <Link
                  className={classes.link}
                  href="https://www.apple.com/apple-podcasts/"
                >
                  Apple Podcasts
                </Link>
              </li>

              <li className={classes.listItem}>
                <Link
                  className={classes.link}
                  href="https://www.apple.com/apple-books/"
                >
                  Apple Books
                </Link>
              </li>

              <li className={classes.listItem}>
                <Link
                  className={classes.link}
                  href="https://www.apple.com/legal/privacy/"
                >
                  Privacy Policy
                </Link>
              </li>
            </ul>
          </Grid>
        </Grid>
      </Container>
    </StyledGrid>
  );
};

export default Footer;

import React from "react";
import { styled } from "@mui/material/styles";
import { createFragmentContainer, RelayProp } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";
import { ContentPartnerPage_template$data } from "./__generated__/ContentPartnerPage_template.graphql";

import NewSubmission from "../components/NewSubmission";
import { Grid, Typography, Divider, Link as LinkOut } from "@mui/material";
import ErrorPage from "./ErrorPage";

const PREFIX = "ContentPartnerPage";

const classes = {
  root: `${PREFIX}-root`,
  divider: `${PREFIX}-divider`,
  requirements: `${PREFIX}-requirements`,
  requirementBullets: `${PREFIX}-requirementBullets`,
  requirementsBold: `${PREFIX}-requirementsBold`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.root}`]: {
    flexGrow: 1,
  },

  [`& .${classes.divider}`]: {
    margin: "40px 0",
  },

  [`& .${classes.requirements}`]: {
    fontSize: "16px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.63,
    letterSpacing: "0.32px",
    color: "#444444",
  },

  [`& .${classes.requirementBullets}`]: {
    fontSize: "16px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.63,
    letterSpacing: "0.32px",
    color: "#444444",
    margin: "0 0  20px 0",
  },

  [`& .${classes.requirementsBold}`]: {
    fontSize: "16px",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.63,
    letterSpacing: "0.32px",
    color: "#444444",
    fontWeight: 500,
  },
}));

interface Props {
  relay: RelayProp;
  template: ContentPartnerPage_template$data;
  router?: any;
  match?: any;
}

const ContentPartnerPage = ({ router, template }: Props) => {
  return (
    <Root>
      {template.version ? (
        <NewSubmission
          router={router}
          tagName={template.tag?.name}
          version={template.version}
        >
          <Grid item xs={12}>
            <Divider className={classes.divider} />
            <Grid container spacing={3}>
              <Grid item sm={3}>
                <Typography variant="h3">Program Requirements</Typography>
              </Grid>
              <Grid item sm={9}>
                <Grid container spacing={3} style={{ padding: "0 10px" }}>
                  <Typography variant="body1" className={classes.requirements}>
                    If you <b>do not</b> have content on one of our Apple Media
                    Stores (ex. Apple Music, Apple Books, etc.), do not apply
                    here. Please use{" "}
                    <LinkOut href="/other-partners">this form</LinkOut> instead.
                  </Typography>
                  <Typography variant="body1" className={classes.requirements}>
                    <br />{" "}
                    <span className={classes.requirementsBold}>
                      Baseline requirements for entering the program include:
                    </span>
                  </Typography>
                  <ul className={classes.requirementBullets}>
                    <li>
                      Willingness to work on a CPA (cost per acquisition) basis;
                      there’s no pay out on CPC (cost per click) or any other
                      payment method
                    </li>
                    <li>
                      Proof of working business relationship with Apple Services
                    </li>
                    <li>Ability to respond to Apple within 48 hours</li>
                    <li>
                      Compliance with Apple Services Partner Program{" "}
                      <LinkOut href="https://apple.co/pp-partnerizetc">
                        Terms and Conditions
                      </LinkOut>
                    </li>
                  </ul>
                  <Typography variant="body1" className={classes.requirements}>
                    Please note that we will only consider complete
                    applications. Once you submit your application to the
                    program, we will review it and determine if you are a good
                    fit. If you meet the requirements, we will let you know.
                  </Typography>
                  <Typography variant="body1" className={classes.requirements}>
                    <br />{" "}
                    <span>
                      View the Apple Services Performance Partner Program terms
                      and conditions{" "}
                      <LinkOut href="https://apple.co/pp-partnerizetc">
                        here
                      </LinkOut>
                    </span>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </NewSubmission>
      ) : (
        //TODO ensure this never happens or handle this in a elegant public facing way
        <ErrorPage status={404} />
      )}
    </Root>
  );
};

export default createFragmentContainer(ContentPartnerPage, {
  template: graphql`
    fragment ContentPartnerPage_template on Template {
      id
      name
      type
      slug
      tag {
        name
      }
      version {
        id
        name
        description
        ...NewSubmission_version
      }
    }
  `,
});

import React, { ReactNode } from "react";
import { Grid } from "@mui/material";
import MenuBar from "./MenuBar";
import Footer from "./Footer";
import ScrollUp from "./ScrollUp";

type Props = {
  children?: ReactNode;
};

const Layout = ({ children }: Props) => {
  return (
    <>
      <ScrollUp />
      <Grid
        container
        direction="column"
        alignItems="center"
        style={{ minHeight: "100vh" }}
      >
        <Grid item container xs={12}>
          <MenuBar />
        </Grid>
        <Grid item container xs={12} style={{ flexGrow: 1 }}>
          {children}
        </Grid>
        <Grid item container>
          <Footer />
        </Grid>
      </Grid>
    </>
  );
};

export default Layout;

import { graphql } from "babel-plugin-relay/macro";

export const otherTemplates = graphql`
  query formTemplatesOtherQuery($templateTypes: [TemplateTypeEnum!]) {
    ...OtherPartnersPage_query @arguments(templateTypes: $templateTypes)
  }
`;

export const contentTemplate = graphql`
  query formTemplateContentQuery {
    template(tag: "CONTENT") {
      id
      ...ContentPartnerPage_template
    }
  }
`;

export const otherTemplate = graphql`
  query formTemplateOtherQuery($slug: String!) {
    template(slug: $slug) {
      id
      version {
        ...NewSubmission_version
      }
    }
  }
`;

export const vipTemplate = graphql`
  query formTemplateVipQuery($key: String!) {
    templateToken(key: $key) {
      ...VIPPartnerPage_templateToken
    }
  }
`;

export const japaneseTemplate = graphql`
  query formTemplateJapaneseQuery {
    template(tag: "JAPANESE") {
      id
      ...JapanesePartnerPage_template
    }
  }
`;

// Container.tsx is meant to take an array of fields,
// set up their values, and map out the components
import React from "react";
import { Grid } from "@mui/material";

// Types
import { Field, SimpleObject } from "./formHelpers";
import InputVisibility from "./InputVisibility";

// New inputs
import {
  FieldSet,
  ErrorField,
  SeparatorInput,
  TextAreaInput,
  TextInput,
  AddressInput,
  CountrySelectInput,
  SelectInput,
  RadioInput,
  CheckboxInput,
  NestedAttributesInput,
  ContainerWrapper,
  CountryInput,
  AffiliateUsernameInput,
} from "./inputs";

// Old inputs
// import AdamInput from "../renderings/fields/AdamInput";
// import SwitchInput from "../renderings/fields/SwitchInput";

const componentList: SimpleObject = {
  TEXT: TextInput,
  SEPARATOR: SeparatorInput,
  FIELDSET: FieldSet,
  TEXT_AREA: TextAreaInput,
  ADDRESS: AddressInput,
  COUNTRY_SELECT: CountrySelectInput,
  SELECT: SelectInput,
  RADIO_GROUP: RadioInput,
  CHECKBOX_GROUP: CheckboxInput,
  NESTED_ATTRIBUTES: NestedAttributesInput,
  CONTAINER: ContainerWrapper,
  COUNTRY: CountryInput,
  AFFILIATE_USERNAME: AffiliateUsernameInput,
};

type Props = {
  field: { fields: Field[] } | Field;
  forceShowErrors: boolean;
  disabled: boolean;
  onChange: (fieldKey: string, data: any) => void;
  setValidity: (key: string, valid: boolean) => void;
};

// component
const FieldContainer: React.FC<Props> = ({
  field,
  setValidity,
  ...passAlong
}) => {
  if (!field.fields) return <></>;

  const fieldTags = field.fields.map((field: Field, index: number) => {
    const TagName = componentList[field.kind] || ErrorField;
    return (
      <InputVisibility key={field.key} setValidity={setValidity} field={field}>
        <Grid
          item
          {...field.data.display}
          ref={(ref: HTMLDivElement) => {
            field.ref = ref;
          }}
        >
          <TagName field={field} setValidity={setValidity} {...passAlong} />
        </Grid>
      </InputVisibility>
    );
  });

  return <>{fieldTags}</>;
};

export default FieldContainer;

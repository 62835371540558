import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { reaction } from "mobx";
import { observer } from "mobx-react-lite";
import { checkCheckboxValidity, InputFieldProps } from "../formHelpers";
import {
  Grid,
  FormControl,
  FormGroup,
  FormControlLabel,
  FormHelperText,
} from "@mui/material";

import { BasicLabel, BasicCheckbox } from "./CustomInputs";
import ReactMarkdown from "react-markdown";

const PREFIX = "CheckboxInput";

const classes = {
  formControl: `${PREFIX}-formControl`,
  label: `${PREFIX}-label`,
  radioButtons: `${PREFIX}-radioButtons`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.formControl}`]: {
    margin: theme.spacing(0),
    minWidth: 120,
  },

  [`& .${classes.label}`]: {
    marginBottom: "10px",
  },

  [`& .${classes.radioButtons}`]: {
    marginRight: "45px",
  },
}));

type Check = {
  label: string;
  value: string;
};

export type Option = {
  label: string;
  value: string;
};

const CheckboxInput: React.FC<InputFieldProps> = observer(
  ({ field, forceShowErrors, disabled, onChange, setValidity }) => {
    const [errorMessage, setErrorMessage] = useState("");
    const [showErrors, setShowErrors] = useState(false);

    const checkValidation = () => {
      // check text validations
      const errors = checkCheckboxValidity(field);
      // set error messages
      setErrorMessage(errors.join(" "));
      setValidity(field.key, errors.length === 0);
    };

    // weird syntax to do watch like in vue
    useEffect(() => {
      reaction(() => {
        return Object.values(field.value.data || {});
      }, checkValidation);
      if (!field.data?.validation?.required) checkValidation();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // check validation on init and when forceShowErrors updates
    useEffect(() => {
      checkValidation();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [forceShowErrors]);

    useEffect(() => {
      setShowErrors((forceShowErrors || field.changed) && errorMessage !== "");
    }, [forceShowErrors, errorMessage, field.changed]);

    if (!field.value.data) field.value.data = {};
    const checkValues = field.value?.data;

    // map options for checkbox group
    const checkOptions = field.data.options.map((check: Check) => {
      return (
        <Grid item xs={6} key={check.label}>
          <FormControlLabel
            key={check.label}
            control={
              <BasicCheckbox
                checked={checkValues[check.value] || false}
                onChange={(e: any) => {
                  field.changed = true;
                  const currentData = { ...field.value.data };
                  currentData[check.value] = e.target.checked;
                  onChange(field.key, currentData);
                }}
                name={check.value}
              />
            }
            label={<span style={{ color: "#444" }}>{check.label}</span>}
          />
        </Grid>
      );
    });

    // render component
    return (
      <Root>
        <FormControl
          disabled={disabled}
          className={classes.formControl}
          fullWidth
          error={showErrors}
        >
          <BasicLabel>{field.name}</BasicLabel>
          <FormGroup row aria-label="checks">
            <Grid container item xs={12}>
              {checkOptions}
            </Grid>
          </FormGroup>
          <FormHelperText component="span">
            <ReactMarkdown linkTarget={"_blank"}>
              {showErrors ? errorMessage : field.data?.settings?.helpText}
            </ReactMarkdown>
          </FormHelperText>
        </FormControl>
      </Root>
    );
  },
);

export default CheckboxInput;

import React from "react";
import { styled } from "@mui/material/styles";
import { Divider, Grid, Typography, FormHelperText } from "@mui/material";
import FieldContainer from "../FieldContainer";
import { InputFieldProps } from "../formHelpers";
import ReactMarkdown from "react-markdown";

const PREFIX = "FieldSet";

const classes = {
  divider: `${PREFIX}-divider`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")({
  [`& .${classes.divider}`]: {
    margin: "40px 0",
  },
});

const FieldSet: React.FC<InputFieldProps> = ({ field, ...passAlong }) => {
  return (
    <Root>
      <Divider className={classes.divider} />
      <Grid container spacing={3}>
        <Grid item sm={3}>
          <Typography variant="h3">{field.name}</Typography>
        </Grid>
        <Grid item sm={9}>
          <Grid container spacing={3}>
            <FieldContainer field={field} {...passAlong} />
            <Grid item xs={12}>
              <FormHelperText component="span">
                <ReactMarkdown linkTarget={"_blank"}>
                  {field.data?.settings?.helpText}
                </ReactMarkdown>
              </FormHelperText>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Root>
  );
};

export default FieldSet;

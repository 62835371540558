import React from "react";
import { Grid, Divider } from "@mui/material";

interface Props {
  prop: any;
}

const SeparatorInput = (props: Props) => {
  return (
    <Grid item xs={12}>
      <Divider />
    </Grid>
  );
};

export default SeparatorInput;

// Force it to use any instead of stupid 'unknown'
// TODO: remove once rendering data is fully typed in the graph
export type FieldValue = {
  data?: any;
  templateFieldId: string;
  id?: string;
};

export type FieldUpdate = {
  key: string;
  data?: any;
  id?: string;
};

export type ValidityUpdate = {
  key: string;
  valid: boolean;
};

export type FieldSettings = {
  id: string;
  name: string;
  kind: string;
  key: string;
  data: any | null;
  parentId: string;
  displayOrder: number;
};

export type AnyData = {
  data: any;
};

export type Fields = {
  [key: string]: Field;
};

export type Field = {
  id: string;
  name: string;
  kind: string;
  key: string;
  displayKey: string;
  data: any | null;
  parentId?: string;
  displayOrder: number;
  value: FieldValue;
  valid: boolean;
  changed: boolean;
  fields?: Field[];
  ref: HTMLDivElement | null;
};

export type Validities = {
  [key: string]: boolean;
};

export type FieldsByParentId = {
  [key: string]: Field[];
};

export type SimpleObject = {
  [key: string]: any;
};

export type InputFieldProps = {
  field: Field;
  forceShowErrors: boolean;
  disabled: boolean;
  onChange: (fieldKey: string, data: any) => void;
  setValidity: (key: string, valid: boolean) => void;
};

const layoutFieldsKinds = ["FIELDSET", "SEPARATOR", "CONTAINER"];

function getNodeFieldsByKey(
  fieldsTree: any,
  includeLayoutFields: boolean,
  result: any = {}
) {
  fieldsTree.forEach((field: any) => {
    if (includeLayoutFields || !layoutFieldsKinds.includes(field.kind)) {
      result[field.key] = field;
    }

    if (field.fields && field.kind !== "NESTED_ATTRIBUTES") {
      getNodeFieldsByKey(field.fields, includeLayoutFields, result);
    }
  });

  return result;
}

//setting up field tree
function nestFields(levelFields: Field[], fieldsByParentId: FieldsByParentId) {
  if (!levelFields) return [];

  levelFields.forEach((field: any) => {
    field.fields = nestFields(
      fieldsByParentId[field.id] || [],
      fieldsByParentId
    );
  });
  return levelFields;
}

function convertToTree(fieldsArray: Field[], rootId: string = "root") {
  const fieldsByParentId = fieldsArray.reduce(
    (result: FieldsByParentId, field: Field) => {
      const parentId = field.parentId || "root";
      result[parentId] = result[parentId] || [];
      result[parentId].push(field);
      return result;
    },
    {}
  );

  return nestFields(fieldsByParentId[rootId], fieldsByParentId);
}

function initFieldsArray(templateFields: any, renderingFields?: any) {
  const renderingFieldsHash = (renderingFields || []).reduce(
    (res: SimpleObject, rfield: any) => {
      res[rfield.templateField.id] = rfield;
      return res;
    },
    {}
  );

  return templateFields.map((field: FieldSettings) => {
    const renderingField = renderingFieldsHash[field.id];

    return {
      ...field,
      displayKey: field.key,
      value: {
        templateFieldId: field.id,
        data: renderingField?.data,
        id: renderingField?.id,
      },
      valid: false,
      changed: false,
    };
  });
}

export function initFieldsTree(templateFields: any, renderingFields?: any) {
  return convertToTree(initFieldsArray(templateFields, renderingFields));
}

// For initalizing fields like address
function initGroupFields(
  templateFields: any,
  fieldValue?: any,
  index: number = 0
) {
  const initialData = fieldValue.data || {};

  return templateFields.map((field: FieldSettings) => {
    return {
      ...field,
      displayKey: `${field.key}_${index}`,
      value: {
        templateFieldId: field.id,
        data: initialData[field.key],
      },
      valid: false,
    };
  });
}

export function initGroupFieldsTree(
  templateFields: any,
  fieldValue?: any,
  rootId?: string,
  index?: number
) {
  return convertToTree(
    initGroupFields(templateFields, fieldValue, index),
    rootId
  );
}

export function createFormStore(source: any) {
  return {
    fieldsTree: source.tree,
    get editableFieldsByKey() {
      return getNodeFieldsByKey(this.fieldsTree, false);
    },
    get allFieldsByKey() {
      return getNodeFieldsByKey(this.fieldsTree, true);
    },
    updateField(fieldKey: string, data: any) {
      const field = this.editableFieldsByKey[fieldKey];
      if (field) field.value.data = data;
    },
    updateValidity(fieldKey: string, valid: boolean) {
      const field = this.editableFieldsByKey[fieldKey];
      if (field) {
        field.valid = valid;
      } else {
        // See if it was a layout field and try that
        // `updateValidity` for layout fields only gets called when visiblitly is set.
        const layoutField = this.allFieldsByKey[fieldKey];
        if (
          layoutFieldsKinds.includes(layoutField.kind) &&
          layoutField.fields
        ) {
          layoutField.fields.map((field: any) =>
            this.updateValidity(field.key, valid)
          );
        }
      }
    },
    get valid() {
      const invalidFields = Object.values(this.editableFieldsByKey).filter(
        (field: any) => !field.valid
      );
      return invalidFields.length === 0;
    },
    get firstInvalid() {
      return Object.values(this.editableFieldsByKey).find(
        (field: any) => !field.valid
      );
    },
    forceShowErrors: false,
    inProgress: false,
  };
}

// VALIDITY CHECKS
function checkMin(fieldValue: string, min: number) {
  if (min === 0) return;

  if (fieldValue.length < min) {
    return `Input must be at least ${min} character(s) long.`;
  }
}
function checkMax(fieldValue: string, max: number) {
  if (max === 0) return;

  if (fieldValue.length > max) {
    return `Input must be no more than ${max} character(s) long.`;
  }
}
function checkPattern(fieldValue: string, pattern: string) {
  const regex = RegExp(pattern);
  if (regex.test(fieldValue) === false) {
    return `Input is not formatted properly.`;
  }
}

export function checkTextValidity(field: Field) {
  const fieldValue = field.value.data || "";
  const validation = field.data.validation || {};
  const errors = [];

  //check for validations, and add errors
  if (fieldValue.length === 0) {
    if (validation.required) {
      errors.push("Input is required.");
    } else {
      // field is empty but not required so bail.
      return [];
    }
  }

  const minError = checkMin(fieldValue, validation.min);
  if (minError) errors.push(minError);

  const maxError = checkMax(fieldValue, validation.max);
  if (maxError) errors.push(maxError);

  const matchError = checkPattern(fieldValue, validation.pattern);
  if (matchError) errors.push(matchError);

  return errors;
}

export function checkOptionsValidity(field: Field) {
  const fieldValue = field.value.data || "";
  const validation = field.data.validation || {};
  const acceptedValues = validation.acceptedValues || [];
  const errors = [];

  //check for validations, and add errors
  if (fieldValue.length === 0 && validation.required) {
    errors.push("Select an option.");
    return errors;
  }

  if (acceptedValues.length !== 0) {
    if (!acceptedValues.includes(fieldValue)) {
      const errorMessage =
        acceptedValues.length === 1
          ? `Must select "${acceptedValues[0]}" to continue`
          : `Must select one of the following to continue: ${acceptedValues.join(
              ", "
            )}`;
      errors.push(errorMessage);
    }
  }

  return errors;
}

export function checkMultiOptionsValidity(field: Field) {
  const fieldValue = field.value.data || [];
  const validation = field.data.validation || {};
  const errors = [];

  //check for validations, and add errors
  if (fieldValue.length === 0) {
    if (validation.required) {
      errors.push("Select an option.");
    } else {
      // field is empty but not required so bail.
      return [];
    }
  }

  return errors;
}

export function checkCheckboxValidity(field: Field) {
  const fieldValue = field.value.data || {};
  const selectedValues = Object.keys(fieldValue).filter(
    (key) => fieldValue[key]
  );
  const numberChecked = selectedValues.length;
  const validation = field.data.validation || {};
  const acceptedValues = validation.acceptedValues || [];
  const acceptedValuesOnly = validation.acceptedValuesOnly || false;
  const errors = [];

  if (numberChecked === 0 && validation.required) {
    errors.push("Select an option.");
    return errors;
  }

  if (acceptedValues.length !== 0) {
    let isAcceptable = false;

    if (acceptedValuesOnly) {
      isAcceptable = selectedValues.every((value) => {
        return acceptedValues.includes(value);
      });
    } else {
      isAcceptable = selectedValues.some((value) => {
        return acceptedValues.includes(value);
      });
    }

    if (!isAcceptable) {
      const errorMessage =
        acceptedValues.length === 1
          ? `Must select "${acceptedValues[0]}" to continue`
          : `Must ${
              acceptedValuesOnly ? "select only from" : "include one of"
            } the following to continue: ${acceptedValues.join(", ")}`;
      errors.push(errorMessage);
    }
  }

  return errors;
}

import React from "react";
import { Container, Box } from "@mui/material";
import Spinner from "./Spinner";

interface Props {}

const LoadingPage: React.FC<Props> = () => {
  return (
    <Container maxWidth="lg">
      <Box
        style={{
          position: "absolute",
          top: "calc(50% - 50px)",
          left: "calc(50% - 50px)",
        }}
      >
        <Spinner size="100px" />
      </Box>
    </Container>
  );
};

export default LoadingPage;

import React from "react";
import { createRoot } from "react-dom/client";
import * as serviceWorker from "./serviceWorker";

import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import theme from "./theme";
import { RelayEnvironmentProvider } from "./config/RelayEnvironmentProvider";
import RelayRouter from "./config/RelayRouter";

import "./index.css";

const rootElement = document.getElementById("root");
if (!rootElement) throw new Error("Failed to find the root element");
const root = createRoot(rootElement);

root.render(
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <RelayEnvironmentProvider>
        <RelayRouter />
      </RelayEnvironmentProvider>
    </ThemeProvider>
  </StyledEngineProvider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

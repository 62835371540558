import React, { useState, useEffect } from "react";

import { reaction } from "mobx";
import { observer } from "mobx-react-lite";
import { checkOptionsValidity, InputFieldProps } from "../formHelpers";
import {
  Grid,
  FormControl,
  RadioGroup,
  FormControlLabel,
  FormHelperText,
} from "@mui/material";

import { BasicLabel, BasicRadio } from "./CustomInputs";
import ReactMarkdown from "react-markdown";

export type Option = {
  label: string;
  value: string;
};

const RadioInput: React.FC<InputFieldProps> = observer(
  ({ field, forceShowErrors, disabled, onChange, setValidity }) => {
    const [errorMessage, setErrorMessage] = useState("");
    const [showErrors, setShowErrors] = useState(false);

    const checkValidation = () => {
      // check text validations
      const errors = checkOptionsValidity(field);
      // set error messages
      setErrorMessage(errors.join(" "));
      setValidity(field.key, errors.length === 0);
    };

    // weird syntax to do watch like in vue
    useEffect(() => {
      reaction(() => field.value.data, checkValidation);
      if (!field.data?.validation?.required) checkValidation();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // check validation on init and when forceShowErrors updates
    useEffect(() => {
      checkValidation();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [forceShowErrors]);

    useEffect(() => {
      setShowErrors((forceShowErrors || field.changed) && errorMessage !== "");
    }, [forceShowErrors, errorMessage, field.changed]);

    // map options for radio
    const radioOptions = field.data.options.map((option: Option) => {
      return (
        <Grid item xs={6} key={option.value}>
          <FormControlLabel
            key={option.value}
            value={option.value}
            control={<BasicRadio />}
            label={<span style={{ color: "#444" }}>{option.label}</span>}
            labelPlacement="end"
          />
        </Grid>
      );
    });

    // render component
    return (
      <>
        <FormControl disabled={disabled} fullWidth error={showErrors}>
          <BasicLabel>{field.name}</BasicLabel>

          <RadioGroup
            row
            value={field.value.data || ""}
            onChange={(e) => {
              field.changed = true;
              onChange(field.key, e.target.value);
            }}
          >
            {radioOptions}
          </RadioGroup>

          <FormHelperText component="span">
            <ReactMarkdown linkTarget={"_blank"}>
              {showErrors ? errorMessage : field.data?.settings?.helpText}
            </ReactMarkdown>
          </FormHelperText>
        </FormControl>
      </>
    );
  },
);

export default RadioInput;
